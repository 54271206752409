import React, { useState, useEffect, useRef } from "react";
import workFromHome from "../assets/svg/workFromHome.svg";

import { useTranslation, Trans } from "react-i18next";

import Header from "../components/Header";
import AcademicRecord from "./AcademicRecord";
import Edmentum from "./Edmentum";
import MapTest from "./MapTest";
import Resources from "./Resources";
import StickyMenu from "../navigation/StickyMenu";
import StudentInformation from "./StudentInfomation";
import ScrollToTop from "../navigation/ScrollToTop";

function Dashboard({
  loggedInParent,
  selectedChild,
  selectedChild_UserID,
  studentInfo,
  setStudentInfo,
  gradeId,
  classId,
  lang,
  minimized,
  centerScreen,
  token,
  isMobile,
  isTablet,
}) {
  useEffect(() => {}, [studentInfo]);

  const { t, i18n } = useTranslation();

  const [activeView, setActiveView] = useState(0);
  const [mouseOverSection, setmouseOverSection] =
    useState("StudentInformation");

  useEffect(() => {
    switch (mouseOverSection) {
      case "StudentInformation":
        setActiveView(0);
        break;
      case "AcademicRecord":
        setActiveView(1);
        break;
      case "MapTest":
        setActiveView(2);
        break;
      case "Edmentum":
        setActiveView(3);
        break;
      case "Resources":
        setActiveView(4);
        break;
      default:
        break;
    }
  }, [mouseOverSection]);

  const screenRef = useRef(null);

  const scrollToTopA = () => {
    console.log("scroll");
    screenRef.current.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      {/* <StickyMenu
        activeView={activeView}
        setActiveView={setActiveView}
        lang={lang}
      /> */}
      <ScrollToTop scrollToTopA={() => scrollToTopA()} lang={lang} />
      <div className={centerScreen} ref={screenRef}>
        <div className="PageTitle DashboardHeader">
          {t("sideBar.Dashboard")}
        </div>

        <div id="StudentInformation">
          <StudentInformation
            selectedChildId={selectedChild}
            selectedChild_UserID={selectedChild_UserID}
            studentInfo={studentInfo}
            setStudentInfo={setStudentInfo}
            setmouseOverSection={setmouseOverSection}
            lang={lang}
            token={token}
            isMobile={isMobile}
            isTablet={isTablet}
          />
        </div>
        <div id="AcademicRecord">
          <AcademicRecord
            selectedChildId={selectedChild}
            setmouseOverSection={setmouseOverSection}
            selectedChild_UserID={selectedChild_UserID}
            longStudentId={studentInfo && studentInfo.st_Id}
            lang={lang}
            grade={studentInfo && studentInfo.grade}
            token={token}
            isMobile={isMobile}
            isTablet={isTablet}
          />
        </div>
        <div id="MapSection">
          <MapTest
            selectedChild_UserID={studentInfo && studentInfo.student_Id}
            setmouseOverSection={setmouseOverSection}
            lang={lang}
            token={token}
            isMobile={isMobile}
            isTablet={isTablet}
          />
        </div>

        <div id="Resources">
          <Resources
            selectedChildId={selectedChild}
            schoolCode={studentInfo && studentInfo.schoolCode}
            gradeId={gradeId}
            classId={classId}
            setmouseOverSection={setmouseOverSection}
            lang={lang}
            token={token}
            isMobile={isMobile}
            isTablet={isTablet}
          />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
