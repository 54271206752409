import React, { useState, useEffect } from "react";
import emiratiMan from "../assets/emirati.PNG";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import phone from "../assets/svg/phone.svg";
import { useTranslation, Trans } from "react-i18next";
import { PregnantWomanRounded } from "@mui/icons-material";

export default function SchoolContactInfo({
  ContactTitle,
  PhoneContact,
  lang,
}) {
  const { t, i18n } = useTranslation();
  return (
    <div elevation={3} className="schoolManag ">
      <div className="schoolManag2">
        <div style={{ padding: "0px", width: "100%" }}>
          <div className="SchoolProfileTitle ">{ContactTitle}</div>
          <div style={{ padding: "0px", width: "100%" }}>
            <div className="p10 mobileP5">
              <div className="spaceBetween greyColor">
                <span
                  dir="ltr"
                  style={{ whiteSpace: "pre-wrap" }}
                  className="arabicDontChange"
                >
                  {" "}
                  {PhoneContact}{" "}
                </span>
                <div dir="ltr">{/*  */}</div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            lang == "EN" ? "MobileCenterImage BLD" : "MobileCenterImage BRD"
          }
        >
          <img src={phone} className="PhoneImg scale08" />
        </div>
      </div>
    </div>
  );
}
