import React, { useState } from "react";

import whiteCorner from "../assets/svg/roundedCorner.png";

function SideBarBtn({
  onBtnClick,
  BtnId,
  index,
  activeView,
  Text,
  className,
  IconClass,
  minimized,
  WhiteIcon,
  YellowIcon,
  BlueIcon,
  isMobile,
  lang,
}) {
  const [hover, setHover] = useState(false);

  const getIconSrc = () => {
    if (hover) {
      return YellowIcon;
    } else {
      if (index == activeView) {
        return isMobile ? YellowIcon : BlueIcon;
      } else {
        return isMobile ? WhiteIcon : WhiteIcon;
      }
    }
  };

  const getTextClass = () => {
    if (minimized) {
      if (hover) {
        return lang == "EN"
          ? "minSideBarBtn selectedBackgroundMin"
          : "minSideBarBtn selectedBackgroundMinAR";
      } else {
        if (index == activeView) {
          return lang == "EN"
            ? "minSideBarBtn selectedBtn selectedBackgroundMin"
            : "minSideBarBtn selectedBtn selectedBackgroundMinAR";
        } else {
          return "minSideBarBtn ";
        }
      }
    } else {
      if (hover) {
        return lang == "EN"
          ? "sideBarButton sideBarButtonHover"
          : "sideBarButton sideBarButtonHoverAR";
      } else {
        if (index == activeView) {
          return lang == "EN"
            ? "sideBarButton selectedBtn selectedBackground"
            : "sideBarButton selectedBtn selectedBackgroundAR";
        } else {
          return "sideBarButton ";
        }
      }
    }
  };
  return (
    <div
      className="navBarWrapper"
      id={BtnId}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div onClick={() => onBtnClick(index)} className={getTextClass()}>
        <img src={getIconSrc()} className={IconClass} />
        {!minimized && <div className="sideBarText">{Text}</div>}
      </div>
    </div>
  );
}

export default SideBarBtn;
