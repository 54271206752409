import React, { useState, useEffect } from "react";

import DesktopView from "./Views/DesktopView";
import MobileView from "./Views/MobileView";
import { useTranslation, Trans } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "react-native-crypto-js";
import { useMediaQuery } from "react-responsive";
import "./css/App.css";
import "./css/Tablet.css";
import "./css/App125.css";
import "./css/Mobile.css";
import "./css/MobileSmall.css";
import URL from "./Settings.json";
import Login from "./Login/Login";
import "./i18n";
import TabletView from "./Views/TabletView";
import DoneAnimation from "./components/DoneAnimation";
import axios, { isCancel, AxiosError } from "axios";
import moment from "moment";
const _Url = URL._Url;
const SUrl = URL.SUrl;

function App() {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const [isTourOpen, setisTourOpen] = useState(false);

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const [token, setToken] = useState("");

  const [rememberMe, setRememberMe] = useState(false);

  const [lang, setLang] = useState("EN");

  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });

  const isTablet = useMediaQuery({
    query: "(min-device-width : 768px) and (max-device-width : 1600px)",
  });
  const isMobile = useMediaQuery({
    query: "(max-device-width : 767px)",
  });

  const [uservalidated, setUserValidated] = useState(false);
  const [userId, SetuserId] = useState();

  const [errorText, setErrorText] = useState("");
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  const [loggedInParent, setLoggedInParent] = useState({});
  const [loggedIn, setloggedIn] = useState(false);
  const [viewState, setViewState] = useState(0);

  const [selectedChild_UserName, setSelectedChild_UserName] = useState();
  const [selectedChild_Id, setSelectedChild_Id] = useState();

  const [studentInfo, setStudentInfo] = useState();
  const [childName, setChildName] = useState("");

  const [childCount, setChildCount] = useState(0);

  const yearOptions = [{ value: 2022, label: "2022-2023" }];
  const [selectedYear, SetSelectedYear] = useState({
    value: 2022,
    label: "2022-2023",
  });

  const [childrenOptions, setChildOptions] = useState();
  const [selectedChild_C, setSelectedChild_C] = useState();

  const [childn, setChildN] = useState();

  useEffect(() => {
    const location = window.location;
    let text = location.toString();
    const PToken = text.split("?t=");
    const query_param = "Bearer " + PToken[1];

    if (query_param) {
      // setISSAML(false)
      console.log(query_param);
      axios
        .get(_Url + `/auth/validatesaml/PP`, {
          headers: { Authorization: query_param },
        })
        .then(function (response) {
          setToken("Bearer " + response.data.token);

          if (response.data.email != "") {
            SetuserId(response.data.staffType); //4500073010

            setUserValidated(true);
          } else {
            localStorage.setItem("loggedIn", false);
            setUserName("");
            setPassword("");
            toast.error("Login Failed", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setErrorText("");
          }
        })
        .catch(function (error) {
          // handle error

          if (PToken[1]) {
            window.location.href = "https://saml.charterschools.ae/Logout";
          } else {
            logOut();
          }
        })
        .finally(function () {
          // always executed
        });
    } else {
      console.log("no query_param");
    }
  }, []);

  const logOut = () => {
    setSelectedChild_Id(undefined);
    setStudentInfo(undefined);
    setSelectedChild_UserName(undefined);
    setUserName("");
    setPassword("");
    setStudentInfo(undefined);
    console.log("LOG OUT");
    // localStorage.setItem("loggedIn", false);
    // localStorage.setItem("rememberMe", false);
    // localStorage.setItem("user", "");
    // localStorage.setItem("password", "");
    setUserValidated(false);
    setloggedIn(false);
  };

  useEffect(() => {
    selectedChild_Id &&
      axios
        .get(_Url + `/api/PPMainService/v2/students/${selectedChild_Id}`, {
          headers: { Authorization: token },
        })
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          setStudentInfo(data[0]);
        })
        .catch((error) => {
          console.log(error);
        });
  }, [selectedChild_Id]);

  const childClicked = (StudendId, studentUSerName, index, childName) => {
    console.log("childChanged");
    console.log(StudendId);
    setSelectedChild_Id(StudendId);
    setSelectedChild_UserName(studentUSerName);

    setChildName(childName);
  };
  useEffect(() => {
    let x = [];
    let yy = {};
    loggedInParent.parent_Id &&
      axios
        .get(
          _Url +
            `/api/PPMainService/v2/parentwithchildren/${loggedInParent.parent_Id}`,
          {
            headers: { Authorization: token },
          }
        )
        .then(function (response) {
          console.log(response);
          return response.data;
        })
        .then((data) => {
          console.log(data);
          setChildN(data);

          data.map((d, index) => {
            var xx = {
              value: d.studentId,
              label:
                lang == "EN"
                  ? d.studentFirstName + " " + d.studentFamilyName
                  : d.studentArabicFirstName + " " + d.studentArabicFamilyName,
            };
            if (index == 0) {
              yy = xx;
            }
            x = [...x, xx];
          });

          try {
            childClicked(
              data[0].studentId,
              data[0].studentUserName,
              0,
              data[0].studentFirstName
            );
          } catch (error) {}
        })
        .then(() => {
          console.log("students are");
          console.log(x);
          setChildOptions(x);
          setChildCount(x.length);
          setSelectedChild_C(yy);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(function () {
          // always executed
        });

    // loggedInParent.parent_Id &&
    //   setParentURL(
    //     _Url +
    //       `/api/File/GetImage/${loggedInParent.parent_Id}?time=` +
    //       moment().format("ddMMyyyyhhmmsstt")
    //   );
  }, [loggedInParent.parent_Id, lang]);

  useEffect(() => {
    studentInfo &&
      axios
        .get(
          _Url +
            `/api/GetStudentInfo/${studentInfo.schoolCode}/${studentInfo.grade}`,
          { headers: { Authorization: token } }
        )
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          if (data && data.length > 0) {
            setSelectedChild_Grade(data[0].id);
          }
        })
        .catch((error) => {
          console.log("error here?");
          console.log(error);
        });
  }, [studentInfo]);

  useEffect(() => {
    // const isLoggedIn = localStorage.getItem("loggedIn");
    // console.log("logged in is .... refressshing");
    // console.log(isLoggedIn);
    // isLoggedIn && setloggedIn(isLoggedIn);
    // if (isLoggedIn === "true") {
    //   console.log("in ifff");
    //   const user = rememberMe ? localStorage.getItem("user") : "";
    //   const pass = rememberMe ? localStorage.getItem("password") : "";
    //   let bytes = CryptoJS.AES.decrypt(pass, "parentPortalKey");
    //   let originalText = bytes.toString(CryptoJS.enc.Utf8);
    //   setUserName(user);
    //   setPassword(originalText);
    //   setRememberMe(rememberMe);
    //   logIn();
    // }
  }, []);

  const logIn = () => {
    console.log(userName);
    console.log(password);

    axios
      .get(_Url + `/api/PPMainService/v2/validate/${userName}/${password}`)
      .then(function (response) {
        // handle success
        console.log(response);
        console.log(response.data.token);
        setToken("Bearer " + response.data.token);

        if (response.data.email != "") {
          SetuserId(userName);

          setUserValidated(true);
        } else {
          localStorage.setItem("loggedIn", false);
          setUserName("");
          setPassword("");
          toast.error("Login Failed", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setErrorText("");
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        toast.error("Login Failed", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .finally(function () {
        // always executed
      });

    // fetch(_Url + `/api/PPMainService/v2/validate/${userName}/${password}`)
    //   .then((response) => {
    //     console.log(response);
    //     // return response.json();
    //   })
    //   .then((data) => {
    //     console.log(data);

    //     console.log(data.token);
    //     setToken("Bearer " + data.token);

    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  // useEffect(() => {
  //   axios
  //     .get(SUrl + `/api/academicyears`, { headers: { Authorization: token } })
  //     .then(function (response) {
  //       // handle success
  //       console.log(response);
  //     })
  //     .catch(function (error) {
  //       // handle error
  //       console.log(error);
  //     })
  //     .finally(function () {
  //       // always executed
  //     });
  // }, [token]);

  const [showSideBar, setShowSideBar] = useState(false);

  const [selectedhild_Grade, setSelectedChild_Grade] = useState();
  return (
    <>
      {/* {isMobile ? "Mobile" : "mobile false"}
      {showSideBar ? "show side bar" : "hide side bar"}
      {isDesktopOrLaptop ? "Desktop" : "not desktop"}
      {isTablet ? "tablet" : "not tablet"} */}

      <ToastContainer />
      {loggedIn && (
        <>
          {!isMobile && (
            <DesktopView
              loggedInParent={loggedInParent}
              setLoggedInParent={setLoggedInParent}
              loggedIn={loggedIn}
              setloggedIn={setloggedIn}
              viewState={viewState}
              setViewState={setViewState}
              selectedChild_UserName={selectedChild_UserName}
              setSelectedChild_UserName={setSelectedChild_UserName}
              selectedChild_Id={selectedChild_Id}
              setSelectedChild_Id={setSelectedChild_Id}
              selectedhild_Grade={selectedhild_Grade}
              setSelectedChild_Grade={setSelectedChild_Grade}
              studentInfo={studentInfo}
              setStudentInfo={setStudentInfo}
              showSideBar={showSideBar}
              setShowSideBar={setShowSideBar}
              lang={lang}
              setLang={setLang}
              logOut={logOut}
              token={token}
              isTourOpen={isTourOpen}
              setisTourOpen={setisTourOpen}
              isMobile={isMobile}
              isTablet={isTablet}
              yearOptions={yearOptions}
              selectedYear={selectedYear}
              SetSelectedYear={SetSelectedYear}
              childCount={childCount}
              setChildCount={setChildCount}
              childrenOptions={childrenOptions}
              setChildOptions={setChildOptions}
              selectedChild_C={selectedChild_C}
              setSelectedChild_C={setSelectedChild_C}
              childn={childn}
              setChildN={setChildN}
              childClicked={childClicked}
              childName={childName}
              setChildName={setChildName}
            />
          )}
          {isMobile && (
            <MobileView
              loggedInParent={loggedInParent}
              setLoggedInParent={setLoggedInParent}
              loggedIn={loggedIn}
              setloggedIn={setloggedIn}
              viewState={viewState}
              setViewState={setViewState}
              selectedChild_UserName={selectedChild_UserName}
              setSelectedChild_UserName={setSelectedChild_UserName}
              selectedChild_Id={selectedChild_Id}
              setSelectedChild_Id={setSelectedChild_Id}
              selectedhild_Grade={selectedhild_Grade}
              setSelectedChild_Grade={setSelectedChild_Grade}
              studentInfo={studentInfo}
              setStudentInfo={setStudentInfo}
              showSideBar={showSideBar}
              setShowSideBar={setShowSideBar}
              lang={lang}
              setLang={setLang}
              logOut={logOut}
              token={token}
              isTourOpen={isTourOpen}
              setisTourOpen={setisTourOpen}
              isMobile={isMobile}
              isTablet={isTablet}
              yearOptions={yearOptions}
              selectedYear={selectedYear}
              SetSelectedYear={SetSelectedYear}
              childrenOptions={childrenOptions}
              setChildOptions={setChildOptions}
              selectedChild_C={selectedChild_C}
              setSelectedChild_C={setSelectedChild_C}
              childn={childn}
              setChildN={setChildN}
              childClicked={childClicked}
              childName={childName}
              setChildName={setChildName}
            />
          )}
        </>
      )}
      {!loggedIn && (
        <Login
          loggedInParent={loggedInParent}
          setLoggedInParent={setLoggedInParent}
          loggedIn={loggedIn}
          setloggedIn={setloggedIn}
          lang={lang}
          setLang={setLang}
          logIn={logIn}
          setUserName={setUserName}
          password={password}
          setPassword={setPassword}
          rememberMe={rememberMe}
          setRememberMe={setRememberMe}
          uservalidated={uservalidated}
          setUserValidated={setUserValidated}
          userId={userId}
          SetuserId={SetuserId}
          errorText={errorText}
          setErrorText={setErrorText}
          token={token}
          isMobile={isMobile}
          isTablet={isTablet}
        />
      )}
    </>
  );
}

export default App;
