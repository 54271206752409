import scrollToTop from "../assets/svg/ScrollToTop.png";

function ScrollToTop({ scrollToTopA, lang }) {
  return (
    <div
      className={
        lang == "EN" ? "ScrollToTop ScrollToTopEN" : "ScrollToTop ScrollToTopAR"
      }
      onClick={() => scrollToTopA()}
    >
      <img src={scrollToTop}></img>
    </div>
  );
}

export default ScrollToTop;
