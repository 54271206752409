import React, { useState, useEffect } from "react";
import infoIconn from "../assets/svg/blueInfo.svg";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import CloseIcon from "@mui/icons-material/Close";

function GradeExplanationMobile({ Type, Explanation, lang, roundedClass }) {
  return (
    <div>
      <div className="MobilFlexRow mtop10">
        <div className={roundedClass}></div>
        <div
          className={
            lang == "EN"
              ? "AlignLeft legendTextMap"
              : "AlignRight legendTextMap"
          }
        >
          {Type}
        </div>
      </div>
      <div className="">
        <div
          className={
            lang == "EN" ? "AlignLeft MobileEx" : "AlignRight MobileEx"
          }
        >
          {Explanation}
        </div>
      </div>
    </div>
  );
}

export default GradeExplanationMobile;
