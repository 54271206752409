import phone from "../assets/svg/charterIcons/Phone.svg";
import Email from "../assets/svg/charterIcons/Email.svg";
import facebook from "../assets/svg/charterIcons/Facebook.svg";

import twitter from "../assets/svg/charterIcons/twitter.svg";
import instagram from "../assets/svg/charterIcons/instagram.svg";

import topImage from "../assets/svg/charterIcons/topWhiteDesign.svg";
import bottomImage from "../assets/svg/charterIcons/bottomYellowDesing.svg";
import schoolEdu from "../assets/schoolEdu.png";
import { useTranslation, Trans } from "react-i18next";
import { useMediaQuery } from "react-responsive";

function LargeFooterView({ lang, isTablet, isMobile }) {
  const { t, i18n } = useTranslation();

  return (
    <div className="LargeFooterView">
      <div className="FooterWrap">
        <div>
          {!isMobile && (
            <>
              <img src={schoolEdu} className="schoolEdu"></img>

              <img src={topImage} className="absoluteTopFooter"></img>
              <img src={bottomImage} className="absoluteBottomFooter"></img>
            </>
          )}
        </div>

        <div className="bottomFooter">
          <div className="LoginFooterRow">
            <div className="smallFooterText mobileMargin">
              {t("FooterView.DepofEdu")}
            </div>
            {/* <div className="AlwaysFlexRow">
              <a href="" target="_Blank">
                {" "}
                <img src={phone} className="FooterIcon"></img>
              </a>
              <a href="https://www.facebook.com/ADEKabudhabi/" target="_Blank">
                <img src={facebook} className="FooterIcon"></img>
              </a>
              <a href="https://twitter.com/Adek_tweet" target="_Blank">
                <img src={twitter} className="FooterIcon"></img>
              </a>
              <a
                href="https://www.instagram.com/accounts/login/?next=/Adek_Insta/"
                target="_Blank"
              >
                <img src={instagram} className="FooterIcon"></img>
              </a>
            </div> */}
            <div className="AlwaysFlexRow">
              <a
                href="https://www.tamm.abudhabi/en/privacy-statement"
                target="_blank"
                className="LargeFooterLink"
              >
                {t("FooterView.PrivacyPolicy")}
              </a>
              <div className="yellowTriangle"></div>
              <a
                href="https://www.tamm.abudhabi/en/terms-and-conditions"
                target="_blank"
                className="LargeFooterLink"
              >
                {t("FooterView.TermsCondition")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LargeFooterView;
