import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Header from "../components/Header";
import moment from "moment";
import URL from "../Settings.json";

import { useTranslation, Trans } from "react-i18next";
import NoDataToDisplay from "../components/NoDataToDisplay";
import TermDropDown from "../components/TermDropDown";
import AttendanceBtn from "./AttendanceBtn";

import ArrowDown from "../assets/svg/ArrowDown.svg";

import axios from "axios";
const _Url = URL._Url;

function Attendance({
  ChildUserName,
  loggedInParent,
  selectedStudentId,
  lang,
  minimized,
  centerScreen,
  token,
  studentInfo,
  isMobile,
  isTablet,
}) {
  const [addendanceData, setaddendanceData] = useState();
  const [selectedTerm, setSelectedTerm] = useState(-1);
  const { t, i18n } = useTranslation();
  const [mobileRow, setMobileRow] = useState(-1);

  const toggleMobileRow = (index) => {
    if (mobileRow === index) {
      setMobileRow(-1);
    } else {
      setMobileRow(index);
    }
  };

  const renderMobileAttendanceData = () => {
    return (
      addendanceData &&
      addendanceData.map((att, index) => {
        return (
          <>
            <tr className="spaceUnder">
              <td className=" arabicDontChange mobileAttendanceLabel">
                {moment(att.attendanceDate)
                  .locale(lang == "EN" ? "en" : "ar")
                  .format(" DD/MM/YYYY")}

                {mobileRow === index && (
                  <div class="mtop10">
                    <div className="MobileAttendanceRow">
                      <div className="mobileAttendanceLabel">
                        {" "}
                        {t("Attendance.AttendanceHeader")}:
                      </div>
                      <div className="mobileAttendanceInfo">
                        {t("Attendance.Absent")}
                      </div>
                    </div>
                    <div className="MobileAttendanceRow">
                      <div className="mobileAttendanceLabel">
                        {" "}
                        {t("Attendance.Excused")}:
                      </div>
                      <div className="mobileAttendanceInfo">
                        {" "}
                        {att.attendanceCode === "UNEXAB"
                          ? lang === "EN"
                            ? "Unexcused"
                            : "بدون عذر"
                          : lang === "EN"
                          ? "Excused"
                          : "بعذر"}
                      </div>
                    </div>
                    <div className="MobileAttendanceRow">
                      <div className="mobileAttendanceLabel">
                        {" "}
                        {t("Attendance.Reason")}:
                      </div>
                      <div className="mobileAttendanceInfo">
                        {att.reasonDescriptionAm}
                      </div>
                    </div>
                  </div>
                )}
              </td>
              <td>
                <div>
                  <img src={ArrowDown} onClick={() => toggleMobileRow(index)} />
                </div>
              </td>
            </tr>
          </>
        );
      })
    );
  };

  const renderAttendanceData = () => {
    return (
      addendanceData &&
      addendanceData.map((att) => {
        return (
          <>
            <tr className="spaceUnder">
              <td className="tableText arabicDontChange">
                {moment(att.attendanceDate)
                  .locale(lang == "EN" ? "en" : "ar")
                  .format(" DD/MM/YYYY")}
              </td>
              <td className="tableText">{t("Attendance.Absent")}</td>
              <td className="tableText">
                {att.attendanceCode === "UNEXAB"
                  ? lang === "EN"
                    ? "Unexcused"
                    : "بدون عذر"
                  : lang === "EN"
                  ? "Excused"
                  : "بعذر"}
              </td>
              <td className="tableText">{att.reasonDescriptionAm}</td>
              <td>
                {" "}
                <AttendanceBtn
                  data={att}
                  refreshData={refreshData}
                  token={token}
                />{" "}
              </td>
            </tr>
          </>
        );
      })
    );
  };

  useEffect(() => {
    refreshData();
  }, [ChildUserName, selectedTerm]);

  const refreshData = () => {
    console.log("attendance data");
    console.log(selectedStudentId);
    setaddendanceData([]);
    selectedStudentId &&
      axios
        .get(
          _Url +
            `/api/Attendance/GetAttendaceByStudentId/${selectedStudentId}/${
              selectedTerm ? selectedTerm : -1
            }`,
          { headers: { Authorization: token } }
        )
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          console.log(data);
          setaddendanceData(data);
        })
        .catch((error) => {
          console.log("error here?");
          console.log(error);
        });
  };
  return (
    <>
      {/* <Header
        studentInfo={studentInfo}
        loggedInParent={loggedInParent}
        message={t("HeaderMsg.Attendance")}
        lang={lang}
        minimized={minimized}
      /> */}
      <div className={centerScreen}>
        <div className="PageTitle mtop50">{t("Attendance.Attendance")}</div>
        <Box>
          <div elevation={3} className="AttendancePaper studentInfoBox p20 ">
            <div className="spaceBetweenRow  MobileOnlyColumn">
              <div></div>
              <TermDropDown
                idd="TermSelectDD"
                setSelectedTerm={setSelectedTerm}
                URL={_Url + `/api/GetTerms`}
                lang={lang}
                token={token}
              />
            </div>

            {!isMobile &&
              !isTablet &&
              addendanceData &&
              addendanceData.length != 0 && (
                <div className="AttendanceTablePadding">
                  <table
                    style={{ width: "90%", tableLayout: "fixed" }}
                    className="attendanceTable"
                  >
                    <thead>
                      <tr>
                        <th className="tA_Header">{t("Attendance.Date")}</th>
                        <th className="tA_Header">
                          {t("Attendance.AttendanceHeader")}
                        </th>
                        <th className="tA_Header">{t("Attendance.Excused")}</th>
                        <th className="tA_Header">{t("Attendance.Reason")}</th>
                        <th>File Entry</th>
                      </tr>
                    </thead>
                    <tbody>{renderAttendanceData()}</tbody>
                  </table>
                </div>
              )}

            {(isMobile || isTablet) &&
              addendanceData &&
              addendanceData.length != 0 && (
                <div className="AttendanceTablePadding">
                  <table
                    style={{ width: "90%", tableLayout: "fixed" }}
                    className="attendanceTable"
                  >
                    <thead>
                      <tr>
                        <th className="tA_MobileHeader">
                          {t("Attendance.Date")}
                        </th>
                        <th className="tA_Header" style={{ width: "10%" }}></th>
                      </tr>
                    </thead>
                    <tbody>{renderMobileAttendanceData()}</tbody>
                  </table>
                </div>
              )}
            <NoDataToDisplay
              noData={
                addendanceData && addendanceData.length === 0 ? true : false
              }
              lang={lang}
            />
          </div>
        </Box>
      </div>
    </>
  );
}

export default Attendance;
