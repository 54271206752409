import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import moment from "moment";

import { useTranslation, Trans } from "react-i18next";
import URL from "../../Settings.json";
import YellowLink from "../../components/YellowLink";
import axios from "axios";
const _Url = URL._Url;

function DeviceInfo({ studentInfo, selectedChild_UserID, lang, token }) {
  const { t, i18n } = useTranslation();
  const [deviceData, setDeviceData] = useState();

  const [deviceHref, setDeviceHref] = useState("");

  useEffect(() => {
    studentInfo &&
      axios
        .get(_Url + `/api/Login/GetDeviceData/${selectedChild_UserID}`, {
          headers: { Authorization: token },
        })
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          console.log(data);
          try {
            setDeviceData(data);
          } catch (error) {}
        })
        .catch((error) => {
          console.log(error);
        });

    studentInfo &&
      axios
        .get(
          _Url +
            `/api/GetPolicyBySchool/${studentInfo.schoolCode}/1
        `,
          {
            headers: { Authorization: token },
          }
        )
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          data && setDeviceHref(data.linkUrl);
        })
        .catch((error) => {
          console.log(error);
        });
  }, [studentInfo]);
  return (
    <div elevation={3} className="  MarginTop5 deviceBox deviceBorder">
      <div className="SectionTitle mleft10"> {t("DeviceInfo.DeviceInfo")}</div>
      <div
        className="roww"
        title={studentInfo ? studentInfo.device_SerialNumber : ""}
      >
        <div className="DeviceT"> {t("DeviceInfo.DevicesNumber")}</div>
        <div className="LabelColin">:</div>
        <div className="arabicDontChange">
          {deviceData && deviceData.serialNum ? deviceData.serialNum : "NA"}
        </div>
      </div>

      <div className="roww">
        <div className="DeviceT"> {t("DeviceInfo.MostAppUsed")}</div>
        <div className="LabelColin">:</div>
        <div className="deviceApp">
          {deviceData && deviceData.data2.length > 0
            ? deviceData.data2[0].app
            : "NA"}
        </div>
      </div>
      <div className="roww">
        <div className="DeviceT"> {t("DeviceInfo.LastLogin")}</div>
        <div className="LabelColin">:</div>{" "}
        <div className="arabicDontChange">
          {deviceData && deviceData.data1[0].lastLogin.length > 0
            ? new moment(deviceData.data1[0].lastLogin)
                .locale(lang == "EN" ? "en" : "ar")
                .format(" DD MMM  YYYY")
            : "NA"}
        </div>
      </div>
      <div className="bottomLink mobileDeviceLink">
        <YellowLink
          Link={t("DeviceInfo.DevicePolicy")}
          href={deviceHref}
          lang={lang}
        />
      </div>
    </div>
  );
}

export default DeviceInfo;
