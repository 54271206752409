import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import URLsy from "../Settings.json";
import axios from "axios";

import filterIcon from "../assets/svg/filterIcon.svg";

const _Url = URLsy._Url;

export default function TermDropDown({ lang }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isHover, setIsHover] = useState(false);

  const getIconclass = () => {
    return isHover
      ? "fa fa-filter sButtonYellowIcon"
      : "fa fa-filter sButtonIcon";
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          border: "border: 1px solid #E7E7EF",
        }}
      >
        {/* <Tooltip title="Filter Term "> */}
        <div
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          className="dowloadBtn"
          onMouseEnter={(e) => setIsHover(true)}
          onMouseLeave={(e) => setIsHover(false)}
        >
          <i className={getIconclass()} aria-hidden="true"></i>
        </div>
        {/* </Tooltip> */}
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <div className="MenuItemText">
            {lang == "EN" ? "Term 1" : "فصل الدراسي 1"}
          </div>
        </MenuItem>
        <Divider />
        <MenuItem>
          <div className="MenuItemText">
            {lang == "EN" ? "Term 2" : "فصل الدراسي 2"}{" "}
          </div>
        </MenuItem>
        <Divider />
        <MenuItem>
          <div className="MenuItemText">
            {lang == "EN" ? "Term 3" : "فصل الدراسي 3"}
          </div>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
