import React, { useState, useEffect } from "react";
import TeacherGradeInfo from "./TeacherGradeInfo";
import Tooltip from "@mui/material/Tooltip";
import TeacherGradeInfoMenu from "./TeacherGradeInfoMenu";

export default function GradeRow({
  SubjectName,
  Term1_MarkOutOf4,
  Term1_MarkOutOf100,
  Term2_MarkOutOf4,
  Term2_MarkOutOf100,
  Term3_MarkOutOf4,
  Term3_MarkOutOf100,
  Term4_MarkOutOf4,
  Term4_MarkOutOf100,
  TeacherName,
  TeacherEmail,
  TeacherGender,
  lang,
  TeacherArabicName,
  grade,
  showTerm3,
  mobileTermShowing,
  isMobile,
  isTablet,
}) {
  const [className1, setClassName1] = useState("roundedDiv emtpy");
  const [className2, setClassName2] = useState("roundedDiv emtpy");
  const [className3, setClassName3] = useState("roundedDiv emtpy ");
  const [className4, setClassName4] = useState("roundedDiv emtpy");
  useEffect(() => {
    setClassName1(getClassByGPA(Term1_MarkOutOf4, Term1_MarkOutOf100));
    setClassName2(getClassByGPA(Term2_MarkOutOf4, Term2_MarkOutOf100));
    setClassName3(getClassByGPA(Term3_MarkOutOf4, Term3_MarkOutOf100));
    setClassName4(getClassByGPA(Term4_MarkOutOf4, Term4_MarkOutOf100));

    console.log(grade);
  }, [Term1_MarkOutOf4]);

  const dg = (x) => {
    if (x === 0) return "";
    else return x;
  };

  const getClassByGPA = (Mark4, Mark100) => {
    if (grade <= 5) {
      if (Mark4 == 0) {
        return "roundedDiv emtpy";
      } else if (Mark4 < 1) {
        return "roundedDiv Below";
      } else if (Mark4 < 2) {
        return "roundedDiv Emerging";
      } else if (Mark4 < 2.5) {
        return "roundedDiv Approaching";
      } else if (Mark4 < 3) {
        return "roundedDiv Meets";
      } else if (Mark4 <= 4) {
        return "roundedDiv Exceeds";
      } else {
        return "roundedDiv";
      }
    } else {
      if (Mark100 == 0) {
        return "roundedDiv emtpy";
      } else if (Mark100 < 60) {
        return "roundedDiv Below";
      } else if (Mark100 < 69) {
        return "roundedDiv Emerging";
      } else if (Mark100 < 79) {
        return "roundedDiv Approaching";
      } else if (Mark100 < 89) {
        return "roundedDiv Meets";
      } else if (Mark100 <= 100) {
        return "roundedDiv Exceeds";
      } else {
        return "roundedDiv";
      }
    }
  };

  return (
    // <Tooltip title={"In " + SubjectName + " a GPA of " + GPA + " was achieved"}>
    <div className="GradeRow">
      <div className="subjectRow">{SubjectName}</div>
      {(mobileTermShowing == 1 || !isMobile) && (
        <div className={className1}>
          {/* {" "}
        {dg(grade <= 5 ? Term1_MarkOutOf4 : Term1_MarkOutOf100)}{" "} */}
        </div>
      )}
      {(mobileTermShowing == 2 || !isMobile) && (
        <div className={className2}>
          {/* {" "}
        {dg(grade <= 5 ? Term2_MarkOutOf4 : Term2_MarkOutOf100)}{" "} */}
        </div>
      )}
      {((showTerm3 && mobileTermShowing == 3) || !isMobile) && (
        <div className={className3}>
          {/* {" "}
          {dg(grade <= 5 ? Term3_MarkOutOf4 : Term3_MarkOutOf100)}{" "} */}
        </div>
      )}
      {(mobileTermShowing == 4 || !isMobile) && (
        <div className={className4}>
          {/* {" "}
        {dg(grade <= 5 ? Term4_MarkOutOf4 : Term4_MarkOutOf100)}{" "} */}
        </div>
      )}{" "}
      <TeacherGradeInfoMenu
        TeacherName={lang == "EN" ? TeacherName : TeacherArabicName}
        TeacherEmail={TeacherEmail}
        Subject={SubjectName}
        TeacherGender={TeacherGender}
        lang={lang}
        isMobile={isMobile}
      />
    </div>
    // </Tooltip>
  );
}
