import { useState } from "react";

import { useTranslation, Trans } from "react-i18next";
function DownloadBtn({ downloadReportCard }) {
  const [isHover, setIsHover] = useState(false);
  const { t, i18n } = useTranslation();

  const getIconclass = () => {
    return isHover
      ? "fa fa-download sButtonYellowIcon"
      : "fa fa-download sButtonIcon";
  };
  return (
    <div className="displayRow">
      {isHover && (
        <div class="YellowBtnText">
          {" "}
          {t("AcademicRecord.DownloadReportCard")}
        </div>
      )}
      <div
        className="dowloadBtn"
        onMouseEnter={(e) => setIsHover(true)}
        onMouseLeave={(e) => setIsHover(false)}
        onClick={downloadReportCard}
      >
        <i className={getIconclass()} aria-hidden="true"></i>
      </div>
    </div>
  );
}

export default DownloadBtn;
