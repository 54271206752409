import charterLogo from "../../assets/charterSchoolsTrans.png";
import Header from "../Header";
import UserMobileMenu from "../UserMobileMenu";

function MobileTopBar({
  activeView,
  setActiveView,
  loggedInParent,
  showSideBar,
  setShowSideBar,
  lang,
  token,

  selectedChild_Id,
  setSelectedChild_Id,
  selectedChild_UserName,
  setSelectedChild_UserName,
  setChildName,
  studentInfo,
  setStudentInfo,

  logOut,
  setisTourOpen,
  isMobile,
  isTablet,
  yearOptions,
  selectedYear,
  SetSelectedYear,
  ShowProfile,
  childCount,
  setChildCount,
  childrenOptions,
  setChildOptions,
  selectedChild_C,
  setSelectedChild_C,
  childn,
  setChildN,
  childClicked,
}) {
  return (
    <>
      <div className="MobileTopBackground mobileHeader">
        <div className="displayRow mobileTop">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              onClick={() => setShowSideBar(!showSideBar)}
              style={{ zIndex: "10000" }}
            >
              {" "}
              <i
                className="fa fa-bars hamburgerMenu"
                aria-hidden="true"
                style={{ color: "white", fontSize: "20px" }}
              ></i>
            </div>
            {/* <div className="MparentName">
              {" "}
              {loggedInParent &&
                (lang == "EN"
                  ? loggedInParent.english_FirstName
                  : loggedInParent.arabic_FirstName)}{" "}
            </div> */}
            <UserMobileMenu
              logOut={logOut}
              setisTourOpen={setisTourOpen}
              parentName={
                lang === "EN"
                  ? loggedInParent.english_FirstName +
                    " " +
                    loggedInParent.english_SecondName +
                    " " +
                    loggedInParent.english_FamilyName
                  : loggedInParent.arabic_FirstName +
                    " " +
                    loggedInParent.arabic_SecondName +
                    " " +
                    loggedInParent.arabic_FamilyName
              }
              isMobile={isMobile}
              isTablet={isTablet}
              ParentId={loggedInParent.parent_Id}
              selectedChild_Id={selectedChild_Id}
              setSelectedChild_Id={setSelectedChild_Id}
              selectedChild_UserName={selectedChild_UserName}
              setSelectedChild_UserName={setSelectedChild_UserName}
              setChildName={setChildName}
              studentInfo={studentInfo}
              setStudentInfo={setStudentInfo}
              ParentGender={loggedInParent.parent_Type}
              loggedInParent={loggedInParent}
              lang={lang}
              token={token}
              yearOptions={yearOptions}
              selectedYear={selectedYear}
              SetSelectedYear={SetSelectedYear}
              childCount={childCount}
              setChildCount={setChildCount}
              childrenOptions={childrenOptions}
              setChildOptions={setChildOptions}
              selectedChild_C={selectedChild_C}
              setSelectedChild_C={setSelectedChild_C}
              childn={childn}
              setChildN={setChildN}
              childClicked={childClicked}
              ShowProfile={ShowProfile}
            />
            {/* <Header
              loggedInParent={loggedInParent}
              selectedChild_Id={selectedChild_Id}
              setSelectedChild_Id={setSelectedChild_Id}
              selectedChild_UserName={selectedChild_UserName}
              setSelectedChild_UserName={setSelectedChild_UserName}
              setChildName={setChildName}
              studentInfo={studentInfo}
              setStudentInfo={setStudentInfo}
              lang={lang}
              token={token}
              logOut={logOut}
              setisTourOpen={setisTourOpen}
              isMobile={isMobile}
              isTablet={isTablet}
            /> */}
          </div>

          <img src={charterLogo} style={{ width: "153px", height: "50px" }} />
        </div>

        {/* {activeView === 0 && (
          <div className="MobileTopBackgroundMini">
            <DashboardHeaderMenu
              dashboardactiveView={dashboardactiveView}
              setdashboardactiveView={setdashboardactiveView}
            />
          </div>
        )} */}

        {/* 
        <div className="MobileTopBackground">
          {activeView !== 0 && (
            <MobileHeaderMenu
              activeView={activeView}
              setActiveView={setActiveView}
              loggedInParent={loggedInParent}
            />
          )}
          {activeView === 0 && (
            <DashboardHeaderMenu
              dashboardactiveView={dashboardactiveView}
              setdashboardactiveView={setdashboardactiveView}
            />
          )}
        </div> */}
      </div>
    </>
  );
}

export default MobileTopBar;
