import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import MapApexChart from "./MapApexChart";
import NoDataToDisplay from "../components/NoDataToDisplay";

import { useTranslation, Trans } from "react-i18next";

import { ToastContainer, toast } from "react-toastify";
import Button from "@mui/material/Button";
import TermDropDown from "../components/TermDropDown";
import Tooltip from "@mui/material/Tooltip";

import URL from "../Settings.json";
import axios from "axios";
const _Url = URL._Url;

function MapTest({
  selectedChild_UserID,
  setmouseOverSection,
  lang,
  token,
  isMobile,
  isTablet,
}) {
  const [selectedTerm, setSelectedTerm] = useState("0");

  const [showMathChart, setShowMathChart] = useState(true);
  const [showScienceChart, setshowScienceChart] = useState(true);
  const [showReadingChart, setShowReadingChart] = useState(true);
  const [showLanguageChart, setShowLanguageChart] = useState(true);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    setShowMathChart(true);
    setshowScienceChart(true);
    setShowReadingChart(true);
    setShowLanguageChart(true);
  }, [selectedChild_UserID]);

  const downloadFile = (fileName) => {
    fetch(fileName, {
      method: "GET",
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = fileName;
          link.target = "_blank";
          link.setAttribute("download", "ReportCard.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const downloadReportCard = () => {
    // toast("No Report Card Found for Student");
    console.log(selectedChild_UserID);
    axios
      .get(_Url + `/api/File/GetReportPath/${selectedChild_UserID}/9/2`, {
        headers: { Authorization: token },
      })
      .then((response) => {
        return response.data;
      })
      .then((datax) => {
        try {
          downloadFile(datax[0].file_FileName);
        } catch (error) {
          toast("MAP Score Unavailable ");
        }
      })
      .catch((error) => {
        toast("MAP Score Unavailable ");
        console.log("error here?");
        console.log(error);
      });
  };

  useEffect(() => {}, [selectedTerm]);
  return (
    <div
      id="MapScoresTest"
      onMouseEnter={() => setmouseOverSection("MapTest")}
      onMouseLeave={() => setmouseOverSection("")}
    >
      <Box className="DSectionBox">
        <span className="compTitleD">{t("MAP.mapTest")}</span>
        <div elevation={3} className="studentInfoBox ">
          <div className="SectionTitle p30"> {t("MAP.mapTitle")} </div>
          <div className="p20 MapPaper ">
            <div className="mapText">
              {t("MAP.mapDescription")}
              <a
                href="https://www.nwea.org/the-map-suite/family-toolkit/"
                target="_blank"
              >
                {" "}
                {t("MAP.mapLink")}
              </a>
              {t("MAP.mapAfter")}
            </div>
            <div elevation={3} className="  inMap">
              <div className="MapDetails ">
                {showMathChart && (
                  <MapApexChart
                    MapTitle={t("MAP.mapTitleMath")}
                    MapSubjectName="Mathematics"
                    StudentId={selectedChild_UserID}
                    setShowChart={setShowMathChart}
                    selectedTerm={selectedTerm}
                    lang={lang}
                    token={token}
                  />
                )}
                {showScienceChart && (
                  <MapApexChart
                    MapTitle={t("MAP.mapTitleScience")}
                    MapSubjectName="Science"
                    StudentId={selectedChild_UserID}
                    setShowChart={setshowScienceChart}
                    selectedTerm={selectedTerm}
                    lang={lang}
                    token={token}
                  />
                )}

                {showReadingChart && (
                  <MapApexChart
                    MapTitle={t("MAP.mapTitleReading")}
                    MapSubjectName="Reading"
                    StudentId={selectedChild_UserID}
                    setShowChart={setShowReadingChart}
                    selectedTerm={selectedTerm}
                    lang={lang}
                    token={token}
                  />
                )}
                {showLanguageChart && (
                  <MapApexChart
                    MapTitle={t("MAP.mapTitleLanguage")}
                    MapSubjectName="Language Arts"
                    StudentId={selectedChild_UserID}
                    setShowChart={setShowLanguageChart}
                    selectedTerm={selectedTerm}
                    lang={lang}
                    token={token}
                  />
                )}
              </div>

              <NoDataToDisplay
                lang={lang}
                noData={
                  showMathChart === false &&
                  showScienceChart === false &&
                  showReadingChart === false &&
                  showLanguageChart === false
                }
              />
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
}

export default MapTest;
