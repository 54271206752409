import React, { useState, useEffect } from "react";

import NoDataToDisplay from "../components/NoDataToDisplay";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

function Edmentum({ setmouseOverSection }) {
  return (
    <div
      id="Edmentum"
      onMouseEnter={() => setmouseOverSection("Edmentum")}
      onMouseLeave={() => setmouseOverSection("")}
    >
      <Box className="DSectionBox">
        <span className="compTitle">Edmentum</span>

        <Paper
          elevation={3}
          style={{ marginTop: "10px", padding: "30px", minHeight: "300px" }}
        >
          <span className="SectionTitle">Edmentum</span>
          <br />
          <NoDataToDisplay noData={true} />
        </Paper>
      </Box>
    </div>
  );
}

export default Edmentum;
