import React, { useState, useEffect } from "react";

import boyUser from "../../assets/svg/maleStudent.svg";
import girlUser from "../../assets/svg/femaleStudent.svg";

function StudentImage({
  studentInfo,
  imageClassName = "userImageStudentMobile",
}) {
  const [genderImage, setGenderImage] = useState(boyUser);

  useEffect(() => {
    try {
      if (studentInfo.gender === "Female") {
        setGenderImage(girlUser);
        setActualPicURL(girlUser);
      } else {
        setGenderImage(boyUser);
        setActualPicURL(boyUser);
      }
    } catch {}

    try {
      setActualPicURL(
        `https://cslp.charterschools.ae/api/api/File/GetStudentImage/${studentInfo.student_Id}`
      );
    } catch (error) {
      setActualPicURL(genderImage);
    }
  }, [studentInfo]);
  const [userImage, setUserImage] = useState(genderImage);

  const [actualPicURL, setActualPicURL] = useState(genderImage);
  return (
    <img
      className={imageClassName}
      src={actualPicURL}
      onError={(e) => (e.target.src = userImage)}
    />
  );
}

export default StudentImage;
