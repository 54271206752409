import React, { useState, useEffect } from "react";

import LargeFooterView from "../Views/LargeFooterView";
import charterLogoTransparent from "../assets/darkBlueLogo.svg";
import yellowArrow from "../assets/svg/charterIcons/yellowPoint.svg";
import LoginPng from "../assets/svg/charterIcons/LoginPng.png";
import decoration1 from "../assets/svg/charterIcons/decoration1.svg";
import decoration2 from "../assets/svg/charterIcons/decoration2.svg";

import CryptoJS from "react-native-crypto-js";

import Checkbox from "@mui/material/Checkbox";

import { ToastContainer, toast } from "react-toastify";
import URL from "../Settings.json";
import axios from "axios";
const _Url = URL._Url;

const location = window.location;
let text = location.toString();
const PToken = text.split("?t=");

function Login({
  loggedInParent,
  setLoggedInParent,
  loggedIn,
  setloggedIn,
  logIn,
  userName,
  setUserName,
  password,
  setPassword,
  rememberMe,
  setRememberMe,
  uservalidated,
  setUserValidated,
  userId,
  SetuserId,
  errorText,
  setErrorText,
  token,
  isMobile,
  isTablet,
}) {
  useEffect(() => {
    if (uservalidated) {
      axios
        .get(_Url + `/api/PPMainService/v2/parents/${userId}`, {
          headers: { Authorization: token },
        })
        .then((response) => {
          return response.data;
        })
        .then((datax) => {
          setLoggedInParent(datax[0]);
          setloggedIn(true);
          localStorage.setItem("loggedIn", true);
        })
        .catch((error) => {
          console.log(error);

          if (PToken[1]) {
            window.location.href = "https://saml.charterschools.ae/Logout";
          }
        });
    }
  }, [uservalidated]);

  useEffect(() => {
    // console.log("in use effeft of loggin");
    // const rememberMe = localStorage.getItem("rememberMe") === "true";
    // console.log(rememberMe);
    // if (rememberMe) {
    //   const user = rememberMe ? localStorage.getItem("user") : "";
    //   console.log(localStorage.getItem("user"));
    //   const pass = rememberMe ? localStorage.getItem("password") : "";
    //   let bytes = CryptoJS.AES.decrypt(pass, "parentPortalKey");
    //   let originalText = bytes.toString(CryptoJS.enc.Utf8);
    //   setUserName(user);
    //   setPassword(originalText);
    //   setRememberMe(rememberMe);
    // } else {
    //   setUserName("");
    //   setPassword("");
    // }
  }, []);

  return (
    <div className="loginpage arabicDontChange">
      <div className="loginHeader">
        <img src={charterLogoTransparent} className="HeaderLogo"></img>
      </div>

      <div className="loginCenter">
        <div className="LeftLogin">
          <img src={decoration1} className="dec1"></img>
          <div className="LoginWelcome">Welcome to the Parent Portal</div>
          <div className="LoginGateway">
            Your gateway to your child's educational journey
          </div>
          <div>
            <div className="LWrap">
              <input
                id="username"
                className="LoginTextbox"
                label="User Name"
                placeholder="User Name"
                autoComplete="current-password"
                value={userName}
                onChange={(event) => setUserName(event.target.value)}
              />

              <input
                id="pass"
                label=""
                className="LoginTextbox"
                placeholder="Password"
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    logIn();
                  }
                }}
                autoComplete="current-password"
              />

              {/* <label className=" LoginRememeberMe">
                <input
                  name="rememberMe"
                  checked={rememberMe}
                  className=""
                  onChange={(event) => setRememberMe(event.target.checked)}
                  type="checkbox"
                />{" "}
                Remember me
              </label> */}
              <span style={{ color: "red" }}>{errorText}</span>

              <div variant="outlined" onClick={logIn} className="loginBtn ">
                <div className="spaceBetweenRow">
                  Sign In
                  <img src={yellowArrow} className=""></img>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <img src={LoginPng} className="HideMobile "></img>
        </div>
      </div>
      <div className="">
        <LargeFooterView isMobile={isMobile} isTablet={isTablet} />
      </div>
    </div>
  );
}

export default Login;
