import React, { useState, useEffect } from "react";

import { useTranslation, Trans } from "react-i18next";
import URL from "../../Settings.json";
import AttendanceBox from "./AttendanceBox";
import ChildInformation from "./ChildInformation";
import StudentImage from "./StudentImage";
const _Url = URL._Url;

function StudentInfo({
  studentInfo,
  lang,
  token,
  admissionDate,
  Unexcused,
  setUnExcused,
  excused,
  setExcused,
  isMobile,
  isTablet,
}) {
  const { t, i18n } = useTranslation();

  return (
    <>
      {" "}
      <div elevation={3} className=" MarginTop5 childInfoBox ">
        <div>
          {isMobile && (
            <div className="p10 MobilFlexRow">
              <StudentImage studentInfo={studentInfo} />
              <AttendanceBox
                studentInfo={studentInfo}
                lang={lang}
                token={token}
                Unexcused={Unexcused}
                setUnExcused={setUnExcused}
                excused={excused}
                setExcused={setExcused}
                isMobile={isMobile}
                isTablet={isTablet}
              />
            </div>
          )}
          <div className="displayRowL smallMobileColumn  mobileStudentInfoTop">
            <div className="StudentImage">
              {!isMobile && (
                <div style={{ textAlign: "center" }}>
                  {" "}
                  <StudentImage studentInfo={studentInfo} />
                </div>
              )}
            </div>
            <ChildInformation
              studentInfo={studentInfo}
              lang={lang}
              admissionDate={admissionDate}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentInfo;
