import moment from "moment";

import { useTranslation, Trans } from "react-i18next";

function ChildInformation({ studentInfo, lang, admissionDate }) {
  const { t, i18n } = useTranslation();
  return (
    <div className="studentInfoRow">
      <div>
        <div className="SectionTitle mleft10">
          {" "}
          {t("StudentInfo.ChildInfo")}
        </div>
      </div>

      <div className="roww">
        {" "}
        <div className="LabelT">{t("StudentInfo.Name")}</div>
        <div className="LabelColin">:</div>{" "}
        <div className="arabicDontChange">
          {studentInfo
            ? lang == "EN"
              ? studentInfo.en_FullName
              : studentInfo.ar_FullName
            : ""}
        </div>
      </div>
      <div className="roww">
        {" "}
        <div className="LabelT">{t("StudentInfo.Age")}</div>
        <div className="LabelColin">:</div>{" "}
        <div className="arabicDontChange">
          {studentInfo ? studentInfo.age : ""}
        </div>
      </div>
      <div className="roww">
        <div className="LabelT">{t("StudentInfo.Grade")}</div>
        <div className="LabelColin">:</div>
        <div className="arabicDontChange">
          {studentInfo ? studentInfo.grade : ""}
        </div>
      </div>
      <div className="roww">
        <div className="LabelT">{t("StudentInfo.Section")}</div>
        <div className="LabelColin">:</div>
        <div className="arabicDontChange">
          <div className="arabicDontChange">
            {studentInfo ? studentInfo.section : ""}
          </div>
        </div>
      </div>
      <div className="roww">
        <div className={lang == "EN" ? "LabelT" : "ARLabelT"}>
          {t("StudentInfo.School")}
        </div>
        <div className="LabelColin">:</div>
        <div className="arabicDontChange">
          {studentInfo
            ? lang == "EN"
              ? studentInfo.schoolName
              : studentInfo.schoolArabicName
            : ""}
        </div>
      </div>
      <div className="roww ">
        <div className={lang == "EN" ? "LabelT" : "ARLabelT"}>
          {t("StudentInfo.UserName")}
        </div>

        <div className="LabelColin">:</div>
        <div className="arabicDontChange">
          {" "}
          {studentInfo ? studentInfo.username : ""}
        </div>
      </div>
      <div className="roww ">
        <div className={lang == "EN" ? "LabelT" : "ARLabelT"}>
          {t("StudentInformation.Date")}
        </div>

        <div className="LabelColin">:</div>
        <div className="arabicDontChange">
          {moment(admissionDate)
            .locale(lang == "EN" ? "en" : "ar")
            .format("DD/MM/YYYY")}
        </div>
      </div>
    </div>
  );
}

export default ChildInformation;
