import ReactSelect from "react-select";
import React, { useState, useEffect } from "react";
import RadialChildSelection from "../components/RadialChildSelection";

import UserMenu from "./UserMenu";
function Header({
  loggedInParent,
  selectedChild_Id,
  setSelectedChild_Id,
  selectedChild_UserName,
  setSelectedChild_UserName,
  setChildName,
  studentInfo,
  setStudentInfo,
  lang,
  token,
  logOut,
  setisTourOpen,
  isMobile,
  isTablet,
  yearOptions,
  selectedYear,
  SetSelectedYear,
  ShowProfile,
  childCount,
  setChildCount,
  childrenOptions,
  setChildOptions,
  selectedChild_C,
  setSelectedChild_C,
  childn,
  setChildN,
  childClicked,
}) {
  return (
    <>
      {(!isMobile || isTablet) && (
        <div className="HeaderDiv">
          <div className="selectDiv yearCombo">
            <ReactSelect
              options={yearOptions}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              onChange={SetSelectedYear}
              value={selectedYear}
              placeholder={"Select Year"}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  neutral50: "white !important", // Placeholder color
                },
              })}
            />
          </div>

          <div className="DisplayD_FlexRow">
            {" "}
            {loggedInParent && (
              <RadialChildSelection
                ParentId={loggedInParent.parent_Id}
                selectedChild_Id={selectedChild_Id}
                setSelectedChild_Id={setSelectedChild_Id}
                selectedChild_UserName={selectedChild_UserName}
                setSelectedChild_UserName={setSelectedChild_UserName}
                setChildName={setChildName}
                studentInfo={studentInfo}
                setStudentInfo={setStudentInfo}
                ParentGender={loggedInParent.parent_Type}
                loggedInParent={loggedInParent}
                lang={lang}
                token={token}
                isMobile={isMobile}
                isTablet={isTablet}
                childCount={childCount}
                setChildCount={setChildCount}
                childrenOptions={childrenOptions}
                setChildOptions={setChildOptions}
                selectedChild_C={selectedChild_C}
                setSelectedChild_C={setSelectedChild_C}
                childn={childn}
                setChildN={setChildN}
                childClicked={childClicked}
              />
            )}
            <div>
              {!isMobile && (
                <UserMenu
                  logOut={logOut}
                  ShowProfile={ShowProfile}
                  setisTourOpen={setisTourOpen}
                  isMobile={isMobile}
                  isTablet={isTablet}
                  studentInfo={studentInfo}
                  parentName={
                    lang === "EN"
                      ? loggedInParent.english_FirstName +
                        " " +
                        loggedInParent.english_SecondName +
                        " " +
                        loggedInParent.english_FamilyName
                      : loggedInParent.arabic_FirstName +
                        " " +
                        loggedInParent.arabic_SecondName +
                        " " +
                        loggedInParent.arabic_FamilyName
                  }
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Header;
