import React, { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import profileIcon from "../assets/svg/ParentProfile.png";

import femaleImage from "../assets/svg/ParentProfile_Female.png";
import maleImage from "../assets/svg/maleTeacher.png";

function ParentProfile({ loggedInParent, lang, centerScreen, childrenCount }) {
  const [parentProfileImage, setParentProfileImage] = useState(profileIcon);

  useEffect(() => {
    console.log(loggedInParent);
    if (loggedInParent.parent_Type === "Father") {
      console.log("father");
      setParentProfileImage(profileIcon);
    } else {
      console.log("mother");
      setParentProfileImage(femaleImage);
    }
  }, []);

  const { t, i18n } = useTranslation();
  return (
    <div>
      <div className={centerScreen}>
        <div className="PageTitle DashboardHeader">
          {t("Profile.MyProfile")}
        </div>
        <div className="ProfileTitle">{t("Profile.ProfileInformation")}</div>
        <div className="ProfileBox p20 ">
          <div class="ProfileImageC">
            {" "}
            <img className="ProfileParentImage" src={parentProfileImage} />
          </div>
          <div className="Flex_Column ProfileC">
            <div className="ProfileTitle">
              {t("Profile.GeneralInformation")}
            </div>
            <div className="roww">
              <div className="LabelT">{t("StudentInfo.Name")}</div>
              <div className="LabelColin">:</div>{" "}
              <div>
                {lang === "EN"
                  ? loggedInParent.english_FirstName +
                    " " +
                    loggedInParent.english_SecondName +
                    " " +
                    loggedInParent.english_FamilyName
                  : loggedInParent.arabic_FirstName +
                    " " +
                    loggedInParent.arabic_SecondName +
                    " " +
                    loggedInParent.arabic_FamilyName}
              </div>
            </div>
            <div className="roww">
              <div className="LabelT">{t("StudentInfo.Email")}</div>
              <div className="LabelColin">:</div>{" "}
              <div>{loggedInParent.secondaryEmail}</div>
            </div>
            <div className="roww">
              <div className="LabelT">{t("SchoolProfile.PhoneNumber")}</div>
              <div className="LabelColin">:</div>{" "}
              <div dir="ltr">{loggedInParent.mobileNo}</div>
            </div>
            <div className="roww">
              <div className="LabelT">{t("Profile.Children")}</div>
              <div className="LabelColin">:</div>
              <div>{childrenCount}</div>
            </div>
          </div>

          <div className="Flex_Column ProfileC">
            <div className="ProfileTitle">{t("Profile.ContactSupport")}</div>

            <div className="roww">
              <div className="LabelT">{t("StudentInfo.Email")}</div>
              <div className="LabelColin">:</div> <div></div>
            </div>
            <div className="roww">
              <div className="LabelT">{t("SchoolProfile.PhoneNumber")}</div>
              <div className="LabelColin">:</div>{" "}
              <div dir="ltr">600 587236</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ParentProfile;
