import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

import infoIconn from "../assets/svg/blueInfo.svg";
import infoHoverIcon from "../assets/svg/infoHover.svg";
import infoSelectedIcon from "../assets/svg/infoSelected.svg";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,

    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    boxShadow: "0px 3px 21px #0e596129",
  },
}));
function GradeExplanationMenu({
  Type,
  Explanation,
  lang,
  roundedClass,
  isMobile,
}) {
  const [isHover, SetIsHover] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getInfoSrc = () => {
    if (isHover) {
      return infoHoverIcon;
    } else {
      return open ? infoSelectedIcon : infoIconn;
    }
  };
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title={Type}>
          <Button
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onMouseEnter={() => SetIsHover(true)}
            onMouseLeave={() => SetIsHover(false)}
          >
            {!isHover && !open && <div className={roundedClass}></div>}
            {(isHover || open == true) && (
              <img className="roundedInfo" src={getInfoSrc()} />
            )}
            <div
              className={
                lang == "EN" ? "AlignLeft legendText" : "AlignRight legendText"
              }
            >
              {Type}
            </div>
          </Button>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        sx={{}}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,

          sx: {
            overflow: "visible",
            border: "1px solid #1BADBA",

            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: lang == "EN" ? undefined : 14,
              right: lang == "EN" ? 14 : undefined,
              borderTop: "1px solid #1BADBA",
              borderLeft: "1px solid #1BADBA",
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <div className={lang == "EN" ? "ExpG AlignLeft" : "ExpG  AlignRight"}>
            <div className="TypeName"> {Type}</div>
            <div className="ExpGrade"> {Explanation}</div>
          </div>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default GradeExplanationMenu;
