import { useTranslation, Trans } from "react-i18next";

function ChartLegend() {
  const { t, i18n } = useTranslation();
  return (
    <div style={{ display: "flex", flexDirection: "row", padding: "10px" }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="studentMap"> </div>
        <div className="lengendText"> {t("ChartLegend.Student")}</div>
      </div>
      <div
        style={{ display: "flex", flexDirection: "row", marginLeft: "40px" }}
      >
        <div className="BenchMark"> </div>
        <div className="lengendText">{t("ChartLegend.Benchmark")}</div>
      </div>
    </div>
  );
}

export default ChartLegend;
