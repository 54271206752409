import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //   .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          SelectTerm: "Select Term",
          langBtn: "عربي",
          welcomeBack: "Hello,",
          HeaderMsg: {
            Child: "Child",
            SchoolProfile:
              "Your child attends the following school. Please find the contact information.",
            Resources: "These are your child's resources",
            MediaGallery: "This is your child's Gallery",
            Attendance:
              "The view is for the whole academic year. Please add a filter for a specific attendance period.",
            Dashboard:
              "Everything you need to know about your child’s educational journey is just a click away!",
          },
          sideBar: {
            Dashboard: "Dashboard",
            SchoolProfile: "School Profile",
            Attendance: "Attendance",
            Resources: "Resources",
            MediaGallery: "Media Gallery",
            SiteTutorial: "Site Tutorial",
            Logout: "Logout",
          },
          Profile: {
            Profile: "Profile",
            MyProfile: "My Profile",
            ProfileInformation: "Profile Information",
            GeneralInformation: "General Information",
            ContactSupport: "Contact Support",
            Children: "Children",
          },
          FooterView: {
            DepofEdu:
              "© 2022 Department of Education and Knowledge. All rights reserved.",
            PrivacyPolicy: "Privacy Policy",
            TermsCondition: "Terms and Conditions",
          },
          StudentInformation: {
            GeneralInfo: "General Information",
            Date: "Admission Date",
          },
          StudentInfo: {
            Name: "Name",
            Age: "Age",
            Grade: "Grade",
            Section: "Section",
            School: "School",
            Email: "Email",
            AttendanceRate: "Attendance Rate",
            Absents: "Attendance",
            Excused: "Excused",
            UnExcused: "Unexcused",
            ChildInfo: "Student’s Information",
            Warnings: "Warning ",
            UserName: "User Name",
            FinalWarning: "Final Warning",
            AdekReferral: "Adek Referral Notice",
            WithdrawlNotice: "Withdrawal Notice",
            Notice: "Notice",
            Teacher: "Teacher",
            TeacherInfoUnavailable: "Teacher information is not available",
            Unavailable: " Unavailable",
          },
          ChartLegend: {
            Student: "Student",
            Benchmark: "Benchmark",
          },
          DeviceInfo: {
            DeviceInfo: "Device Information",
            DevicesNumber: "Serial Number",
            MostAppUsed: "Most Used App",
            LastLogin: "Learning Portal Last Login",
            DevicePolicy: "Device Policy",
          },
          AcademicRecord: {
            Term1: "Term 1",
            Term2: "Term 2",
            Term3: "Term 3",
            Final: "Final",
            Semester1: "Semester 1",
            Semester2: "Term 2",
            AcademicRecord: "Academic Record",
            GradesPerSubject: "Grades Per Subject",
            DownloadReportCard: "Download Report Card ",
            ClickHere: "Click here for teacher's information.",
            Emerging: "Emerging",
            EmergingExp:
              "The student is unable to demonstrate competency in applying standards-based skills/meeting expectations at a basic level at this time.  He/she has exhibited minimal abilities defined by class rubrics, scoring guides, etc. ",
            Approaching: "Approaching",
            ApproachingExp:
              "The student is approaching competency in applying standards-based skills and knowledge at grade level. He/she has exhibited some abilities defined by class rubrics, scoring guides, etc",
            Meets: "Meets",
            MeetsExp:
              "The student demonstrates his/her ability to apply standards-based skills and knowledge for their grade level and can appropriately display abilities as indicated by class rubrics, scoring guides, etc. ",
            Exeeds: "Exceeds",
            ExeedExp:
              "The student demonstrates mastery of standards-based skills and knowledge for their grade level. They understand and apply key concepts and skills with sophistication, consistency, and independence.",
            Below: "Below",
            BelowExp:
              "The student is unable to demonstrate competency in applying standards-based skills/meeting expectations at a basic level currently. He/she is not quite able to exhibit ability/competency defined by class rubrics, scoring guides at this time. ",
          },
          MediaGallery: {
            Gallery: "Gallery",
          },
          Resource: {
            Resources: "Resources",
            Title: "Title",
            Description: "Description",
            LatestResources: "Latest  Resources",
          },
          SchoolProfile: {
            Name: "Name",
            Email: "Email",
            SchoolProfile: "School Profile",
            PhoneNumber: "Phone",
            AboutParent: "About the Parent",
          },
          Attendance: {
            Attendance: "Absence & Tardiness",
            AttendanceHeader: "Absent / Tardy",
            Date: "Date",
            Excused: "Excused / Unexcused ",
            Reason: "Reason",
            Absence: "Total Absent Days",
            NumberOfNotices: "Notices",
            AttendancePolicy: "Attendance Policy",
            Absent: "Absent",
          },
          DateRange: {
            selectDateRange: "Select Date Range",
          },
          MAP: {
            mapTest: "MAP Test",
            mapTitle: "MAP Test RIT Score",
            mapDescription:
              "Measures Of Academic Progress (MAP) measure your child’s growth in English (Reading and Language Usage), Mathematics and Science, it also identifies the ‘next steps’ for your child to assist teachers with teaching and learning. Individual attainment and progress show what your child knows and how much they have grown since the previous administration. You can find more information on MAP ",
            mapLink: "here",
            mapAfter: ". ",
            downloadMap: "Download MAP Results",
            mapTitleMath: "Math",
            mapTitleScience: "Science",
            mapTitleReading: "Reading in English",
            mapTitleLanguage: "English Language Usage",
          },
        },
      },
      ar: {
        translation: {
          SelectTerm: "حدد الفصل الدراسي",
          langBtn: "English",
          welcomeBack: "مرحبا بك",
          HeaderMsg: {
            Child: "اسم الطالب ",
            SchoolProfile:
              "يلتحق ابنك بالمدرسة الآتية، يرجى اعتماد وسائل الاتّصال التالية ",
            Resources: "هذه هي الموارد الخاصّة بولدكم",
            MediaGallery: "هذا هو الملفّ  الصور الخاصّ بابنك ",
            Attendance:
              "هذا هو جدول الحضور للأسبوع الحاليّ. الرّجاء تحديد فترة زمنيّة محدّدة لتفقّد الحضور.",
            Dashboard:
              "وجهتك المتكاملة للحصول على كل المعلومات المتعلقة بأداء ابنك في المدرسة!  ",
          },
          sideBar: {
            Dashboard: " الصفحة الرئيسية",
            SchoolProfile: "ملفّ المدرسة",
            Attendance: "سجل الحضور ",
            Resources: "الموارد",
            MediaGallery: "معرض الصّور",
            SiteTutorial: "دليل المستخدم ",
            Logout: "تسجيل الخروج",
          },
          StudentInformation: {
            GeneralInfo: "معلومات  الطّالب ",
            Date: "تاريخ الالتحاق بالمدرسة   ",
          },
          FooterView: {
            DepofEdu: "© 2022 دائرة التعليم والمعرفة. كل الحقوق محفوظة.",
            PrivacyPolicy: "سياسة الخصوصية",
            TermsCondition: "الأحكام والشروط",
          },
          StudentInfo: {
            Name: "الاسم",
            Age: "العمر",
            Grade: "الصّفّ",
            Section: "الشّعبة",
            School: "اسم المدرسة",
            Email: "البريد الإلكترونيّ",
            AttendanceRate: "معدّل الحضور  ",
            Absents: "سجل الحضور",
            Excused: "بعذر",
            UnExcused: "بدون عذر",
            ChildInfo: "بيانات الطالب",
            Warnings: "  إنذار",
            UserName: "رقم الطالب eSIS ",
            Notice: " إخطار",
            FinalWarning: "إنذار نهائي ",
            AdekReferral: "إخطار إحالة إلى دائرة التعليم والمعرفة",
            WithdrawlNotice: "إخطار السحب",
            Teacher: "معلم/ة",
            TeacherInfoUnavailable: "معلومات المعلم/ة غير متوفرة",
            Unavailable: " غير متوفرة",
          },
          ChartLegend: {
            Student: "درجة الطالب",
            Benchmark: "المعيار ",
          },
          DeviceInfo: {
            DeviceInfo: "معلومات جهاز الطالب",
            DevicesNumber: "رقم الجهاز",
            MostAppUsed: "التّطبيق الأكثر استخدامًا",
            LastLogin: "آخر عملية تسجيل دخول لبوابة التعلم ",
            DevicePolicy: "سياسة استخدام الجهاز",
          },
          AcademicRecord: {
            Term1: "الفصل الدراسي 1",
            Term2: "الفصل الدراسي 2",
            Term3: "الفصل الدراسي 3",
            Final: "التحصيل النهائي ",
            AcademicRecord: "السّجلّ الأكاديميّ",
            GradesPerSubject: "الشهادة الدراسية ",
            DownloadReportCard: "تحميل الشهادة الدراسية  ",
            ClickHere: "اضغط هنا للحصول على معلومات المعلم.",
            Emerging: "يصعد نحو التوقعات",
            EmergingExp:
              "الطالب غير قادر على إثبات الكفاءة في تطبيق المهارات القائمة على المعايير / تلبية التوقعات على المستوى المطلوب في هذا الوقت.  لقد أظهر الحد الأدنى من القدرات المحددة بواسطة نماذج تقييم الدرجات ، وأدلة التقييم ، وما إلى ذلك",
            Approaching: "يقترب من التوقعات",
            ApproachingExp:
              "يقترب الطالب من الكفاءة في تطبيق المهارات والمعرفة القائمة على المعايير على مستوى الصف. لقد أظهر بعض القدرات التي تحددها نماذج تقييم الدرجات ، وأدلة التقييم ، وما إلى ذلك",
            Meets: "يحقق التوقعات",
            MeetsExp:
              "يوضح الطالب قدرته على تطبيق المهارات والمعرفة القائمة على المعايير للمستوى المطلوب ويمكنه عرض القدرات بشكل مناسب كما هو موضح في نماذج الفصل ونموذج تقييم الدرجات وما إلى ذلك",
            Exeeds: "يفوق التوقعات",
            ExeedExp:
              "يظهر الطالب إتقانا للمهارات والمعرفة القائمة على المعايير للمستوى المطلوب. يفهم الطالب ويطبق المفاهيم والمهارات الأساسية بتطور واتساق واستقلالية",
            Below: "دون التوقعات",
            BelowExp:
              "الطالب غير قادر على إثبات الكفاءة في تطبيق المهارات القائمة على المعايير / تلبية التوقعات على المستوى المطلوب حاليا. هو / هي غير قادر تماما على إظهار القدرة / الكفاءة المحددة بواسطة نماذج تقييم الدرجات وأدلة التقييم في هذا الوقت",
          },
          MediaGallery: {
            Gallery: "معرض الصور ",
          },
          Resource: {
            Resources: "الموارد",
            Title: "العنوان",
            Description: "الوصف",
            LatestResources: "أحدث الموارد ",
          },
          SchoolProfile: {
            Name: " الاسم",
            Email: "البريد الالكترونيّ",
            SchoolProfile: "الملفّ التّعريفيّ الخاصّ بالمدرسة",
            PhoneNumber: "رقم الهاتف",
            AboutParent: "بيانات ولي الأمر",
          },
          Attendance: {
            Attendance: "سجل الحضور ",
            AttendanceHeader: "الغياب والتأخير",
            Date: "التّاريخ",
            Excused: "بعذر/ بدون عذر",
            Reason: "السبب",
            Absence: "مجموع أيام الغياب  ",
            NumberOfNotices: "عدد الإخطارات",
            AttendancePolicy: "سياسة الحضور",
            Absent: "غائب",
          },
          DateRange: {
            selectDateRange: "حدد نطاق التاريخ",
          },
          MAP: {
            mapTest: "اختبار MAP ",
            mapTitle: "اختبار قياس التقدم الأكاديمي (MAP) ",
            mapDescription:
              "اختبار MAP هو تقييم على الحاسوب الآلي يقيس مدى تقدم الطالب الأكاديمي بغض النظر عن الصف الدراسي الملتحق به و يكشف مدى تغير أدائه في الفترة ما بين الاختبارين. وتتكيف الأسئلة على حسب مستوى الطالب مما يعطي نتائج حول كم المعرفة الأكاديمية للطالب ومقدار تقدمه الفردي. لمزيد من المعلومات عن الاختبار، يرجى الضغط",
            mapLink: "هنا",
            mapAfter: ". ",
            downloadMap: "تحميل نتائج MAP ",
            mapTitleMath: "الرياضيّات",
            mapTitleScience: "العلوم",
            mapTitleReading: "القراءة باللغة الانجليزية",
            mapTitleLanguage: "استخدام اللغة الانجليزية",
          },
        },
      },
    },
  });

export default i18n;
