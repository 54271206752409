import React, { useState, useEffect } from "react";

import Header from "../components/Header";
import StickyMenu from "../navigation/StickyMenu";
import Resources from "./Resources";
import AcademicRecord from "./AcademicRecord";
import AttendanceBox from "./components/AttendanceBox";
import AttendanceRate from "./components/AttendanceRate";
import DeviceInfo from "./components/DeviceInfo";
import StudentInfo from "./components/StudentInfo";
import MapTest from "./MapTest";
import { useTranslation, Trans } from "react-i18next";
import StudentInformation from "./StudentInfomation";

function MobileDashboard({
  loggedInParent,
  selectedChild,
  selectedChild_UserID,
  studentInfo,
  setStudentInfo,
  schoolCode,
  gradeId,
  classId,
  dashboardactiveView,
  setdashboardactiveView,
  lang,
  token,
  isMobile,
  isTablet,
  showSideBar,
}) {
  const { t, i18n } = useTranslation();

  return (
    <>
      {/* <StickyMenu activeView={activeView} setActiveView={setActiveView} /> */}
      {/* <Header
        isDashbord={true}
        studentInfo={studentInfo}
        loggedInParent={loggedInParent}
        message={t("HeaderMsg.Dashboard")}
        lang={lang}
      /> */}
      <div
        className={
          showSideBar ? "Foggy MobileCenterScreen" : "MobileCenterScreen"
        }
      >
        <StudentInformation
          selectedChildId={selectedChild}
          selectedChild_UserID={selectedChild_UserID}
          studentInfo={studentInfo}
          setStudentInfo={setStudentInfo}
          lang={lang}
          token={token}
          isMobile={isMobile}
          isTablet={isTablet}
        />
        <AcademicRecord
          selectedChildId={selectedChild}
          selectedChild_UserID={selectedChild_UserID}
          longStudentId={studentInfo && studentInfo.st_Id}
          lang={lang}
          grade={studentInfo && studentInfo.grade}
          token={token}
          isMobile={isMobile}
          isTablet={isTablet}
        />
        <MapTest
          selectedChild_UserID={studentInfo && studentInfo.student_Id}
          lang={lang}
          token={token}
          isMobile={isMobile}
          isTablet={isTablet}
        />
        <Resources
          selectedChildId={selectedChild}
          schoolCode={schoolCode}
          gradeId={gradeId}
          classId={classId}
          lang={lang}
          token={token}
          isMobile={isMobile}
          isTablet={isTablet}
        />
      </div>
    </>
  );
}

export default MobileDashboard;
