import React, { useState, useEffect } from "react";

import { useTranslation, Trans } from "react-i18next";
import URL from "../../Settings.json";
import GradientCircle from "./GradientCircle";

import YellowLink from "../../components/YellowLink";
import axios from "axios";
const _Url = URL._Url;

function AttendanceBox({
  studentInfo,
  lang,
  token,
  Unexcused,
  setUnExcused,
  excused,
  setExcused,
  isMobile,
  isTablet,
}) {
  const [attendanceRate, setAttendanceRate] = useState(0);
  const [attendanceArray, setAttendanceArray] = useState(0);
  const { t, i18n } = useTranslation();

  const [hrefAttendance, setHrefAttendance] = useState("");

  const [addendanceData, setaddendanceData] = useState([100, 0]);
  useEffect(() => {
    let eSum = 0;
    let UnSum = 0;

    addendanceData.map((att) => {
      try {
        if (att.attendanceCode === "UNEXAB") {
          UnSum = UnSum + 1;
        } else {
          eSum = eSum + 1;
        }
      } catch (error) {
        console.log(error);
      }
    });

    setExcused(eSum);
    setUnExcused(UnSum);
  }, [addendanceData]);

  useEffect(() => {
    setaddendanceData([]);
    studentInfo &&
      axios
        .get(
          _Url +
            `/api/Attendance/GetAttendaceByStudentId/${studentInfo.student_Id}/-1`,
          { headers: { Authorization: token } }
        )
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          setaddendanceData(data);
        })
        .catch((error) => {
          console.log(error);
        });

    console.log(studentInfo);
    studentInfo &&
      axios
        .get(_Url + `/api/GetPolicyBySchool/${studentInfo.schoolCode}/0`, {
          headers: { Authorization: token },
        })
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          console.log("policy");
          console.log(data);
          console.log(data.linkUrl);
          data &&
            setHrefAttendance(
              `http://20.46.53.208/PP/api/File/GetFile/${data.linkUrl}`
            );
        })
        .catch((error) => {
          console.log(error);
        });
  }, [studentInfo]);

  useEffect(() => {
    studentInfo &&
      axios
        .get(
          _Url +
            `/api/PPMainService/v2/students/attendanceratebyesisid/${studentInfo.student_Id}`,
          { headers: { Authorization: token } }
        )
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          try {
            let x = data[0].attendanceRate;
            let y = 100 - x;
            let xx = [x, y];
            console.log(xx);
            setAttendanceArray(xx);
            setAttendanceRate(Number(data[0].attendanceRate));
          } catch (error) {}
        })
        .catch((error) => {
          console.log(error);
        });
  }, [studentInfo, lang]);
  return (
    <div
      elevation={3}
      className=" MarginTop5 deviceBox "
      style={{ position: "relative" }}
    >
      <div className="attedanceBox">
        <div>
          {}
          <div className="SectionTitle"> {t("Attendance.Absence")}</div>
          <div className="displayRow">
            <div className="absenceNumber arabicDontChange">
              {excused + Unexcused}
            </div>

            <div className="ExcusedWrap">
              <div className="excusedUnexcused ExcusedTopRow">
                <div className="absesnes arabicDontChange">{excused}</div>
                {t("StudentInfo.Excused")}
              </div>
              <div className="excusedUnexcused">
                <div className="absesnes arabicDontChange">{Unexcused}</div>
                {t("StudentInfo.UnExcused")}
              </div>
            </div>
          </div>
          <div className="SectionTitle mtop20">
            {" "}
            {t("StudentInfo.AttendanceRate")}
          </div>
          <div class="attendanceRate mtop20">{attendanceRate} %</div>

          {/* <div className="absenceNumber arabicDontChange mtop10">
            {Unexcused}
          </div>
          <div className="totalAbs"> {t("Attendance.NumberOfNotices")}</div> */}
        </div>
      </div>
      <div
        className={
          lang == "EN"
            ? "bottomLink mobileAttendanceLink"
            : "bottomLink mobileAttendanceLinkAR"
        }
      >
        <YellowLink
          Link={t("Attendance.AttendancePolicy")}
          href={hrefAttendance}
          lang={lang}
        />
      </div>
    </div>
  );
}

export default AttendanceBox;
