import React, { useState, useEffect } from "react";

import infoIconn from "../assets/svg/blueInfo.svg";
import infoHoverIcon from "../assets/svg/infoHover.svg";
import infoSelectedIcon from "../assets/svg/infoSelected.svg";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Menu from "@mui/material/Menu";
import { useTranslation, Trans } from "react-i18next";

import femaleUser from "../assets/svg/femaleTeacher.png";
import maleUser from "../assets/svg/maleTeacher.png";
import MenuItem from "@mui/material/MenuItem";
export default function TeacherGradeInfoMenu({
  TeacherName,
  TeacherEmail,
  Subject,
  TeacherGender,
  lang,
  isMobile,
}) {
  const { t, i18n } = useTranslation();

  const [isHover, SetIsHover] = useState(false);

  const [imgUrl, setImgUrl] = useState(femaleUser);

  const [imgSrc, setImgSrc] = useState(infoIconn);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getInfoSrc = () => {
    if (isHover) {
      return infoHoverIcon;
    } else {
      return open ? infoSelectedIcon : infoIconn;
    }
  };

  useEffect(() => {
    if (TeacherGender === "female") {
      setImgUrl(femaleUser);
    } else {
      setImgUrl(maleUser);
    }
  }, [TeacherGender]);
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title={t("AcademicRecord.ClickHere")}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onMouseEnter={() => SetIsHover(true)}
            onMouseLeave={() => SetIsHover(false)}
          >
            <img className="infoIcon" src={getInfoSrc()} />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        sx={{}}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          style: {
            width: "85%",
            maxWidth: "85%",
            height: "200px",
          },
          sx: {
            overflow: "visible",
            border: "1px solid #1BADBA",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: lang == "EN" ? undefined : 14,
              right: lang == "EN" ? 14 : undefined,
              borderTop: "1px solid #1BADBA",
              borderLeft: "1px solid #1BADBA",
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <div
            elevation={3}
            className={
              lang == "EN"
                ? "TeacherDetailPopUp EnAlign"
                : "TeacherDetailPopUp ARAlign"
            }
          >
            <div className="TeacherGradeInfoRow ">
              <div>
                <div className="SubjectTitle">
                  {lang == "EN"
                    ? Subject + " " + t("StudentInfo.Teacher")
                    : t("StudentInfo.Teacher") + " " + Subject}
                </div>

                <div className=" MobileOnlyColumn spaceBetweenRow">
                  {TeacherName !== "" && (
                    <div>
                      <div className="roww mtop10">
                        <div className="LabelT">{t("SchoolProfile.Name")}</div>
                        <div className="LabelColin">:</div>
                        <div>{TeacherName}</div>
                      </div>
                      <div className="roww">
                        <div className="LabelT">{t("SchoolProfile.Email")}</div>
                        <div className="LabelColin">:</div>
                        {TeacherEmail}
                      </div>
                    </div>
                  )}
                  {TeacherName == "" && (
                    <div className="TeacherInfoUn mtop20">
                      {t("StudentInfo.TeacherInfoUnavailable")}
                    </div>
                  )}
                </div>
              </div>

              <div
                className={
                  isMobile
                    ? "TeacherInfoImg"
                    : lang == "EN"
                    ? "TeacherInfoImg BLD"
                    : "TeacherInfoImg BRD"
                }
              >
                <img src={imgUrl} className="TeacherImg" />
              </div>
            </div>
          </div>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
