import React, { useState, useEffect, useRef } from "react";

import SchoolProfile from "../SchoolProfile/SchoolProfile";

import Sidebar from "../navigation/Sidebar";
import Dashboard from "../Dashboard/Dashboard";
import Attendance from "../Attendance/Attendance";
import Resources from "../Resources/Resources";
import MediaGallary from "../MediaGallary/MediaGallary";
import FooterView from "./FooterView";
import Header from "../navigation/Header";
import ParentProfile from "../ParentProfile/ParentProfile";
import ScrollToTop from "../navigation/ScrollToTop";

function DesktopView({
  loggedInParent,
  setLoggedInParent,
  loggedIn,
  setloggedIn,
  viewState,
  setViewState,
  selectedChild_UserName,
  setSelectedChild_UserName,
  selectedChild_Id,
  setSelectedChild_Id,
  selectedhild_Grade,
  setSelectedChild_Grade,
  studentInfo,
  setStudentInfo,
  showSideBar,
  setShowSideBar,
  lang,
  setLang,
  logOut,
  token,
  isTourOpen,
  setisTourOpen,
  isMobile,
  isTablet,
  yearOptions,
  selectedYear,
  SetSelectedYear,
  childCount,
  setChildCount,
  childrenOptions,
  setChildOptions,
  selectedChild_C,
  setSelectedChild_C,
  childn,
  setChildN,
  childClicked,
  childName,
  setChildName,
}) {
  const [minimized, setMinimized] = useState(false);

  const getCenterScreen = () => {
    if (minimized || isTablet) {
      return (
        (lang == "EN" ? "MinicenterScreenO" : "MiniARcenterScreenO") +
        (!minimized ? " Foggy" : "")
      );
    } else {
      return lang == "EN" ? "centerScreenO" : "ARcenterScreenO";
    }
  };

  const getViewScreen = () => {
    if (minimized || isTablet) {
      return (
        (lang === "EN" ? "ENdesktopView_Min" : "ARdesktopView_Min") +
        (!minimized ? " Foggy" : "")
      );
    } else {
      return lang === "EN" ? "ENdesktopView" : "ARdesktopView";
    }
  };

  const getSideBarClass = () => {
    if (showSideBar) {
      return "";
    } else return "HideClass";
  };

  const ShowProfile = () => {
    setViewState(5);
  };

  useEffect(() => {}, [studentInfo]);
  return (
    <div className="fixed100">
      <div id="mainScreen">
        <Sidebar
          viewState={viewState}
          setViewState={setViewState}
          loggedInParent={loggedInParent}
          loggedIn={loggedIn}
          setloggedIn={setloggedIn}
          selectedChild_Id={selectedChild_Id}
          setSelectedChild_Id={setSelectedChild_Id}
          selectedChild_UserName={selectedChild_UserName}
          setSelectedChild_UserName={setSelectedChild_UserName}
          studentInfo={studentInfo}
          setStudentInfo={setStudentInfo}
          showSideBar={true}
          setShowSideBar={setShowSideBar}
          lang={lang}
          setLang={setLang}
          minimized={minimized}
          setMinimized={setMinimized}
          logOut={logOut}
          token={token}
          isTourOpen={isTourOpen}
          setisTourOpen={setisTourOpen}
          isMobile={isMobile}
          isTablet={isTablet}
        />

        <div className={getViewScreen()}>
          <Header
            loggedInParent={loggedInParent}
            selectedChild_Id={selectedChild_Id}
            setSelectedChild_Id={setSelectedChild_Id}
            selectedChild_UserName={selectedChild_UserName}
            setSelectedChild_UserName={setSelectedChild_UserName}
            setChildName={setChildName}
            studentInfo={studentInfo}
            setStudentInfo={setStudentInfo}
            lang={lang}
            token={token}
            logOut={logOut}
            setisTourOpen={setisTourOpen}
            isMobile={isMobile}
            isTablet={isTablet}
            yearOptions={yearOptions}
            selectedYear={selectedYear}
            SetSelectedYear={SetSelectedYear}
            ShowProfile={ShowProfile}
            childCount={childCount}
            setChildCount={setChildCount}
            childrenOptions={childrenOptions}
            setChildOptions={setChildOptions}
            selectedChild_C={selectedChild_C}
            setSelectedChild_C={setSelectedChild_C}
            childn={childn}
            setChildN={setChildN}
            childClicked={childClicked}
          />

          {viewState === 0 && (
            <Dashboard
              loggedInParent={loggedInParent}
              selectedChild={selectedChild_Id}
              selectedChild_UserID={selectedChild_UserName}
              studentInfo={studentInfo}
              setStudentInfo={setStudentInfo}
              schoolCode={studentInfo && studentInfo.schoolCode}
              gradeId={selectedhild_Grade}
              classId={studentInfo && studentInfo.classId}
              lang={lang}
              minimized={minimized}
              centerScreen={getCenterScreen()}
              token={token}
              isMobile={isMobile}
              isTablet={isTablet}
            />
          )}
          {viewState === 1 && (
            <SchoolProfile
              selectedChildId={selectedChild_Id}
              loggedInParent={loggedInParent}
              studentInfo={studentInfo}
              lang={lang}
              minimized={minimized}
              centerScreen={getCenterScreen()}
              token={token}
              isMobile={isMobile}
              isTablet={isTablet}
            />
          )}
          {viewState === 2 && (
            <Attendance
              ChildUserName={selectedChild_UserName}
              loggedInParent={loggedInParent}
              studentInfo={studentInfo}
              lang={lang}
              minimized={minimized}
              centerScreen={getCenterScreen()}
              token={token}
              selectedStudentId={selectedChild_UserName.substring(1)}
              isMobile={isMobile}
              isTablet={isTablet}
            />
          )}
          {viewState === 3 && studentInfo && (
            <Resources
              schoolCode={studentInfo.schoolCode}
              gradeId={selectedhild_Grade}
              classId={studentInfo.classId}
              loggedInParent={loggedInParent}
              selectedChildId={selectedChild_UserName}
              studentInfo={studentInfo}
              lang={lang}
              minimized={minimized}
              centerScreen={getCenterScreen()}
              token={token}
              isMobile={isMobile}
              isTablet={isTablet}
            />
          )}
          {viewState === 4 && (
            <MediaGallary
              selectedChild_UserID={studentInfo && studentInfo.student_Id}
              loggedInParent={loggedInParent}
              studentInfo={studentInfo}
              lang={lang}
              minimized={minimized}
              centerScreen={getCenterScreen()}
              token={token}
              isMobile={isMobile}
              isTablet={isTablet}
            />
          )}

          {viewState === 5 && (
            <ParentProfile
              loggedInParent={loggedInParent}
              centerScreen={getCenterScreen()}
              childrenCount={childCount}
            />
          )}
        </div>
      </div>
      <FooterView
        lang={lang}
        isMobile={isMobile}
        isTablet={isTablet}
        minimized={minimized}
      />
    </div>
  );
}

export default DesktopView;
