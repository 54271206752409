import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import boyUser from "../assets/svg/boyUser.svg";
import girlUser from "../assets/svg/girlUser.svg";
import moment from "moment";
import { useTranslation, Trans } from "react-i18next";
import { radioClasses } from "@mui/material";
import StudentInfo from "./components/StudentInfo";
import DeviceInfo from "./components/DeviceInfo";
import AttendanceBox from "./components/AttendanceBox";
import AttendanceRate from "./components/AttendanceRate";

import URL from "../Settings.json";
import axios from "axios";
import ChildInformation from "./components/ChildInformation";
import StudentImage from "./components/StudentImage";
import NoticeWarn from "./components/NoticeWarn";
const _Url = URL._Url;

function StudentInformation({
  selectedChildId,
  selectedChild_UserID,
  studentInfo,
  setStudentInfo,
  setmouseOverSection,
  lang,
  token,
  isMobile,
  isTablet,
}) {
  const { t, i18n } = useTranslation();
  const [admissionDate, setAdmissionDate] = useState("");
  const [excused, setExcused] = useState(0);
  const [Unexcused, setUnExcused] = useState(0);

  useEffect(() => {
    studentInfo &&
      axios
        .get(
          _Url +
            `/api/PPMainService/v2/students/admissiondate/${studentInfo.student_Id}`,
          { headers: { Authorization: token } }
        )
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          console.log(data);
          setAdmissionDate(data.ad);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .finally(function () {
          // always executed
        });

    studentInfo &&
      axios
        .get(
          _Url +
            `/api/PPMainService/v2/students/admissiondate/${studentInfo.student_Id}`,
          { headers: { Authorization: token } }
        )
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          console.log(data);
          setAdmissionDate(data.ad);
        })
        .catch((error) => {
          console.log("error here?");
          console.log(error);
        });
  }, [studentInfo]);
  return (
    <>
      {!(isMobile || isTablet) && (
        <div
          id="StudentInformation"
          onMouseEnter={() => setmouseOverSection("StudentInformation")}
          onMouseLeave={() => setmouseOverSection("")}
        >
          <Box className="DashboardBox">
            <div className="headerRow">
              <div className="compTitleD">
                {t("StudentInformation.GeneralInfo")}
              </div>
              <NoticeWarn Unexcused={Unexcused} />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              className="studentInfo_Box"
            >
              <div className="OuterInfoBox width50">
                <StudentInfo
                  studentInfo={studentInfo}
                  lang={lang}
                  token={token}
                  admissionDate={admissionDate}
                  Unexcused={Unexcused}
                  setUnExcused={setUnExcused}
                  excused={excused}
                  setExcused={setExcused}
                  isMobile={isMobile}
                  isTablet={isTablet}
                />
              </div>
              <div className="OuterInfoBox DeviceBox">
                <DeviceInfo
                  selectedChild_UserID={selectedChild_UserID}
                  studentInfo={studentInfo}
                  lang={lang}
                  token={token}
                  isMobile={isMobile}
                  isTablet={isTablet}
                />
              </div>
              <div className="OuterInfoBox AttendanceUnderWarning">
                <AttendanceBox
                  studentInfo={studentInfo}
                  lang={lang}
                  token={token}
                  Unexcused={Unexcused}
                  setUnExcused={setUnExcused}
                  excused={excused}
                  setExcused={setExcused}
                  isMobile={isMobile}
                  isTablet={isTablet}
                />
                {/* <div
              elevation={3}
              className="studentInfoBox "
              style={{ marginTop: "30px", height: "110px" }}
            >
              <div className="BottomSectionTitle OtherBlueBackGround">
                {" "}
                {t("StudentInformation.Date")}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <div className="DateRow">
                  {moment(admissionDate)
                    .locale(lang == "EN" ? "en" : "ar")
                    .format("DD/MM/YYYY")}
                </div>
              </div>
            </div> */}
              </div>
            </div>
          </Box>
        </div>
      )}
      {isMobile && (
        <>
          <div id="StudentInformation">
            <div className="PageTitle mtop20">
              {t("StudentInformation.GeneralInfo")}
            </div>

            <div className="compTitleD mtop20">
              {t("StudentInformation.GeneralInfo")}
            </div>
            <div className="MobileDashboardWrap">
              <StudentInfo
                token={token}
                studentInfo={studentInfo}
                lang={lang}
                Unexcused={Unexcused}
                setUnExcused={setUnExcused}
                excused={excused}
                setExcused={setExcused}
                isMobile={isMobile}
                isTablet={isTablet}
              />
              <div style={{ marginBottom: "30px" }}>
                <DeviceInfo
                  token={token}
                  selectedChild_UserID={selectedChild_UserID}
                  lang={lang}
                  isMobile={isMobile}
                  isTablet={isTablet}
                />
              </div>
            </div>
            {/* 
          <AttendanceBox token={token} studentInfo={studentInfo} lang={lang} /> */}
          </div>
          <NoticeWarn Unexcused={Unexcused} />
        </>
      )}

      {isTablet && (
        <>
          <div className="headerRow">
            <div className="compTitleD ptop10">
              {t("StudentInformation.GeneralInfo")}
            </div>
            <NoticeWarn Unexcused={Unexcused} />
          </div>
          <div className="studentInfo_Box spaceBetweenRow">
            <div>
              <ChildInformation studentInfo={studentInfo} lang={lang} />{" "}
              <DeviceInfo
                token={token}
                selectedChild_UserID={selectedChild_UserID}
                lang={lang}
                isMobile={isMobile}
                isTablet={isTablet}
              />
            </div>
            <div
              style={{
                borderLeft: "2px dashed rgba(2,10,88, 0.15)",
                paddingLeft: "10px",
              }}
            >
              <StudentImage studentInfo={studentInfo} />
              <div className="mtop20">
                <AttendanceBox
                  studentInfo={studentInfo}
                  lang={lang}
                  token={token}
                  Unexcused={Unexcused}
                  setUnExcused={setUnExcused}
                  excused={excused}
                  setExcused={setExcused}
                  isMobile={isMobile}
                  isTablet={isTablet}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default StudentInformation;
