import MobileTopBar from "../navigation/Mobile/MobileTopBar";
import React, { useState, useEffect } from "react";

import MobileDashboard from "../Dashboard/MobileDashboard";
import SchoolProfile from "../SchoolProfile/SchoolProfile";
import Attendance from "../Attendance/Attendance";
import Resources from "../Resources/Resources";
import MediaGallary from "../MediaGallary/MediaGallary";
import Sidebar from "../navigation/Sidebar";
import Header from "../navigation/Header";
import FooterView from "./FooterView";
import ParentProfile from "../ParentProfile/ParentProfile";

function MobileView({
  loggedInParent,
  setLoggedInParent,
  loggedIn,
  setloggedIn,
  viewState,
  setViewState,
  selectedChild_UserName,
  setSelectedChild_UserName,
  selectedChild_Id,
  setSelectedChild_Id,
  selectedhild_Grade,
  setSelectedChild_Grade,
  studentInfo,
  setStudentInfo,
  showSideBar,
  setShowSideBar,
  lang,
  setLang,
  logOut,
  token,
  isTourOpen,
  setisTourOpen,
  isMobile,
  isTablet,
  yearOptions,
  selectedYear,
  SetSelectedYear,
  childCount,
  setChildCount,
  childrenOptions,
  setChildOptions,
  selectedChild_C,
  setSelectedChild_C,
  childn,
  setChildN,
  childClicked,
}) {
  const [dashboardactiveView, setdashboardactiveView] = useState(0);
  const getSideBarClass = () => {
    if (showSideBar) {
      return "";
    } else return "HideClass";
  };
  const [childName, setChildName] = useState("");
  const getCenterScreen = () => {
    if (showSideBar) {
      return lang == "EN" ? "Foggy centerScreenO" : "Foggy ARcenterScreenO";
    }
    return lang == "EN" ? "centerScreenO" : "ARcenterScreenO";
  };
  useEffect(() => {
    console.log(showSideBar);
  }, [showSideBar]);

  const ShowProfile = () => {
    setViewState(5);
  };

  return (
    <div className="MobileGlobalWrap">
      <div>
        <div className={getSideBarClass()}>
          <Sidebar
            viewState={viewState}
            setViewState={setViewState}
            loggedInParent={loggedInParent}
            loggedIn={loggedIn}
            setloggedIn={setloggedIn}
            selectedChild_Id={selectedChild_Id}
            setSelectedChild_Id={setSelectedChild_Id}
            selectedChild_UserName={selectedChild_UserName}
            setSelectedChild_UserName={setSelectedChild_UserName}
            studentInfo={studentInfo}
            setStudentInfo={setStudentInfo}
            showSideBar={showSideBar}
            setShowSideBar={setShowSideBar}
            lang={lang}
            setLang={setLang}
            logOut={logOut}
            token={token}
            isTourOpen={isTourOpen}
            setisTourOpen={setisTourOpen}
            isMobile={isMobile}
            isTablet={isTablet}
          />
        </div>
        {1 === 1 && (
          <>
            <MobileTopBar
              activeView={viewState}
              setActiveView={setViewState}
              loggedInParent={loggedInParent}
              showSideBar={showSideBar}
              setShowSideBar={setShowSideBar}
              dashboardactiveView={dashboardactiveView}
              setdashboardactiveView={setdashboardactiveView}
              lang={lang}
              token={token}
              selectedChild_Id={selectedChild_Id}
              setSelectedChild_Id={setSelectedChild_Id}
              selectedChild_UserName={selectedChild_UserName}
              setSelectedChild_UserName={setSelectedChild_UserName}
              setChildName={setChildName}
              studentInfo={studentInfo}
              setStudentInfo={setStudentInfo}
              logOut={logOut}
              setisTourOpen={setisTourOpen}
              isMobile={isMobile}
              isTablet={isTablet}
              yearOptions={yearOptions}
              selectedYear={selectedYear}
              SetSelectedYear={SetSelectedYear}
              ShowProfile={ShowProfile}
              childCount={childCount}
              setChildCount={setChildCount}
              childrenOptions={childrenOptions}
              setChildOptions={setChildOptions}
              selectedChild_C={selectedChild_C}
              setSelectedChild_C={setSelectedChild_C}
              childn={childn}
              setChildN={setChildN}
              childClicked={childClicked}
            />

            {viewState === 0 && (
              <MobileDashboard
                loggedInParent={loggedInParent}
                selectedChild={selectedChild_Id}
                selectedChild_UserID={selectedChild_UserName}
                studentInfo={studentInfo}
                setStudentInfo={setStudentInfo}
                schoolCode={studentInfo && studentInfo.schoolCode}
                gradeId={selectedhild_Grade}
                classId={studentInfo && studentInfo.classId}
                dashboardactiveView={dashboardactiveView}
                setdashboardactiveView={setdashboardactiveView}
                lang={lang}
                centerScreen={getCenterScreen()}
                token={token}
                isMobile={isMobile}
                isTablet={isTablet}
                showSideBar={showSideBar}
              />
            )}
            {viewState === 1 && (
              <SchoolProfile
                selectedChildId={selectedChild_Id}
                loggedInParent={loggedInParent}
                studentInfo={studentInfo}
                lang={lang}
                centerScreen={getCenterScreen()}
                token={token}
                isMobile={isMobile}
                isTablet={isTablet}
              />
            )}
            {viewState === 2 && (
              <Attendance
                ChildUserName={selectedChild_UserName}
                loggedInParent={loggedInParent}
                studentInfo={studentInfo}
                lang={lang}
                centerScreen={getCenterScreen()}
                token={token}
                selectedStudentId={selectedChild_UserName.substring(1)}
                isMobile={isMobile}
                isTablet={isTablet}
              />
            )}
            {viewState === 3 && (
              <Resources
                schoolCode={studentInfo.schoolCode}
                gradeId={selectedhild_Grade}
                classId={studentInfo.classId}
                loggedInParent={loggedInParent}
                selectedChildId={selectedChild_UserName}
                studentInfo={studentInfo}
                lang={lang}
                centerScreen={getCenterScreen()}
                token={token}
                isMobile={isMobile}
                isTablet={isTablet}
              />
            )}
            {viewState === 4 && (
              <MediaGallary
                selectedChild_UserID={studentInfo && studentInfo.student_Id}
                loggedInParent={loggedInParent}
                studentInfo={studentInfo}
                lang={lang}
                centerScreen={getCenterScreen()}
                token={token}
                isMobile={isMobile}
                isTablet={isTablet}
              />
            )}
            {viewState === 5 && (
              <ParentProfile
                loggedInParent={loggedInParent}
                centerScreen={getCenterScreen()}
              />
            )}
          </>
        )}
        <FooterView lang={lang} isMobile={isMobile} isTablet={isTablet} />
      </div>
    </div>
  );
}

export default MobileView;
