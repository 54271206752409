import { useTranslation, Trans } from "react-i18next";

function NoticeWarn({ Unexcused }) {
  const { t, i18n } = useTranslation();
  return (
    <div className="NoticeCorner">
      <div className="WarningText">
        {Unexcused < 5 && (
          <div>
            {" "}
            {t("StudentInfo.Notice")} {Unexcused}
          </div>
        )}
        {Unexcused >= 5 && Unexcused <= 8 && (
          <>
            {" "}
            {t("StudentInfo.Warnings")}
            {Unexcused - 4}
          </>
        )}
        {Unexcused == 9 && <>{t("StudentInfo.FinalWarning")}</>}
        {Unexcused == 10 && <> {t("StudentInfo.AdekReferral")}</>}
        {Unexcused > 11 && <>{t("StudentInfo.WithdrawlNotice")}</>}
      </div>
    </div>
  );
}

export default NoticeWarn;
