import { toast } from "react-toastify";
import yellowArrow from "../assets/svg/linkIcon_Blue.svg";

function YellowLink({ Link, href, lang }) {
  return (
    <div>
      <button
        className="yellowLink"
        type="button"
        onClick={(e) => {
          e.preventDefault();
          if (href == "") {
            toast.info(
              lang == "EN" ? Link + " Unavailable " : Link + " غير متوفرة"
            );
          } else {
            window.open(href, "_blank");
          }
        }}
      >
        <img
          src={yellowArrow}
          className={
            lang == "EN"
              ? "yellowLinkArrrow yellowLinkTransform"
              : "yellowLinkArrrow"
          }
        ></img>
        {Link}
      </button>
      {/* <a href={href} target="_blank" className="yellowLink">
      
       
      </a> */}
    </div>
  );
}

export default YellowLink;
