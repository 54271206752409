import * as React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";

import { useTranslation, Trans } from "react-i18next";
import ReactSelect from "react-select";
import boyUser from "../assets/svg/userMobile.png";
import tutorialIcon from "../assets/svg/tutorialIcon.svg";
import profileIcon from "../assets/svg/profileIcon.svg";
import logoutIcon from "../assets/svg/logoutIcon.svg";
import RadialChildSelection from "../components/RadialChildSelection";
import StudentImage from "../Dashboard/components/StudentImage";

export default function UserMobileMenu({
  logOut,
  setisTourOpen,
  parentName,
  isMobile,
  isTablet,
  ParentId,
  selectedChild_Id,
  setSelectedChild_Id,
  selectedChild_UserName,
  setSelectedChild_UserName,
  setChildName,
  studentInfo,
  setStudentInfo,
  ParentGender,
  loggedInParent,
  lang,
  token,
  yearOptions,
  selectedYear,
  SetSelectedYear,
  childCount,
  setChildCount,
  childrenOptions,
  setChildOptions,
  selectedChild_C,
  setSelectedChild_C,
  childn,
  setChildN,
  childClicked,
  childName,
  ShowProfile,
}) {
  const { t, i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            {/* <img className="ProfileImageMiniHeader" src={boyUser} /> */}
            <StudentImage
              studentInfo={studentInfo}
              imageClassName="ProfileImageMiniHeader"
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          style: {
            width: "80%",
            maxWidth: "80%",
          },
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <ListItemIcon>
            <img className="ProfileImageMiniHeader" src={boyUser} />{" "}
          </ListItemIcon>

          <div className="MenuItemText MinMenText">{parentName}</div>
        </MenuItem>

        <Divider />
        <div className="p10">
          <RadialChildSelection
            ParentId={loggedInParent.parent_Id}
            selectedChild_Id={selectedChild_Id}
            setSelectedChild_Id={setSelectedChild_Id}
            selectedChild_UserName={selectedChild_UserName}
            setSelectedChild_UserName={setSelectedChild_UserName}
            setChildName={setChildName}
            studentInfo={studentInfo}
            setStudentInfo={setStudentInfo}
            ParentGender={loggedInParent.parent_Type}
            loggedInParent={loggedInParent}
            lang={lang}
            token={token}
            isMobile={isMobile}
            isTablet={isTablet}
            childCount={childCount}
            setChildCount={setChildCount}
            childrenOptions={childrenOptions}
            setChildOptions={setChildOptions}
            selectedChild_C={selectedChild_C}
            setSelectedChild_C={setSelectedChild_C}
            childn={childn}
            setChildN={setChildN}
            childClicked={childClicked}
          />
          <div className="selectDiv">
            <ReactSelect
              options={yearOptions}
              closeMenuOnSelect={true}
              hideSelectedOptions={false}
              onChange={SetSelectedYear}
              value={selectedYear}
              placeholder={"Select Year"}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  neutral50: "white !important", // Placeholder color
                },
              })}
            />
          </div>
        </div>
        <Divider />
        <MenuItem onClick={() => ShowProfile()}>
          <ListItemIcon>
            <img src={profileIcon} />
          </ListItemIcon>
          <div className="MenuItemText">{t("Profile.Profile")}</div>
        </MenuItem>
        <MenuItem onClick={() => setisTourOpen(true)}>
          <ListItemIcon>
            <img src={tutorialIcon} />
          </ListItemIcon>
          <div className="MenuItemText">{t("sideBar.SiteTutorial")} </div>
        </MenuItem>
        <Divider />
        <MenuItem onClick={logOut}>
          <ListItemIcon>
            <img src={logoutIcon} />
          </ListItemIcon>
          <div className="MenuItemText">{t("sideBar.Logout")}</div>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
