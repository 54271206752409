import React, { useRef, useState, useEffect } from "react";
import progressParent from "../assets/svg/profileBack.png";
import femaleUser from "../assets/svg/maleStudent.svg";
import maleUser from "../assets/svg/maleStudent.svg";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";

import boyUser from "../assets/svg/maleStudent.svg";
import girlUser from "../assets/svg/femaleStudent.svg";

import HoverComponent from "./HoverComponent";
import ExtraChildren from "./ExtraChildren";
import URLsy from "../Settings.json";
import axios from "axios";
import ReactSelect from "react-select";
const _Url = URLsy._Url;

function RadialChildSelection({
  ParentId,
  selectedChild_Id,
  setSelectedChild_Id,
  selectedChild_UserName,
  setSelectedChild_UserName,
  setChildName,
  studentInfo,
  setStudentInfo,
  ParentGender,
  loggedInParent,
  lang,
  token,
  childrenOptions,
  setChildOptions,
  selectedChild_C,
  setSelectedChild_C,
  childn,
  setChildN,
  childClicked,
}) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imgUrl, setImgUrl] = useState(femaleUser);
  const [parenturl, setParentURL] = useState("");

  useEffect(() => {
    if (ParentGender === "Father") {
      setImgUrl(maleUser);
    } else {
      setImgUrl(femaleUser);
    }
  }, [ParentGender]);

  const [userImage, setUserImage] = useState(boyUser);

  const [actualPicURL, setActualPicURL] = useState();

  useEffect(() => {
    try {
      if (studentInfo.gender === "Female") {
        setActualPicURL(girlUser);
      } else {
        setActualPicURL(boyUser);
      }
    } catch {}

    // try {
    //   setActualPicURL(
    //     `https://cslp.charterschools.ae/api/api/File/GetStudentImage/${studentInfo.student_Id}`
    //   );
    // } catch (error) {
    //   if (studentInfo.gender === "Female") {
    //     setActualPicURL(girlUser);
    //   } else {
    //     setActualPicURL(boyUser);
    //   }
    // }
  }, [studentInfo]);

  const inputFileRef = useRef(null);

  useEffect(() => {
    setSelectedImage(null);
  }, []);

  const [isShown, setIsShown] = useState(false);
  const onBtnClick = () => {
    inputFileRef.current.click();
  };
  useEffect(() => {
    try {
      if (selectedImage !== null) {
        setImgUrl(URL.createObjectURL(selectedImage));
      }
    } catch (error) {}
  }, [selectedImage]);

  const onPicChange = (event) => {
    console.log(event.target.files[0]);
    if (event.target.files.length !== 0) {
      setSelectedImage(event.target.files[0]);
    }
  };
  const handleChildChange = (selected) => {
    setSelectedChild_C(selected);
    console.log(selected.value);
    var selectedChild = childn.filter((c) => c.studentId == selected.value)[0];
    console.log(selectedChild);
    childClicked(
      selectedChild.studentId,
      selectedChild.studentUserName,
      0,
      selectedChild.studentFirstName
    );
  };

  return (
    <div>
      <div className="selectDiv">
        <ReactSelect
          options={childrenOptions}
          closeMenuOnSelect={true}
          hideSelectedOptions={false}
          onChange={handleChildChange}
          value={selectedChild_C}
          placeholder={"Select Child"}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              neutral50: "white !important", // Placeholder color
            },
          })}
        />
      </div>
    </div>
  );
}

export default RadialChildSelection;
