import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import URLsy from "../Settings.json";
const _Url = URLsy._Url;

function AttendanceBtn({ data, refreshData, token }) {
  const [hasReason, setHasReason] = useState(false);
  const inputFileRef = useRef(null);

  useEffect(() => {
    if (data.refId) {
      setHasReason(true);
    }
  }, [data]);

  const onBtnClick = () => {
    inputFileRef.current.click();
  };

  const downloadFile = () => {
    console.log("xx");
    fetch(`http://20.46.53.208/PP/api/File/GetFile/${data.refId}`, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        console.log(response);
        response.blob();
      })
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "download.png");

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const downloadReason = () => {
  //   data.users_StudentAtendanceFiles &&
  //     downloadFile(
  //       _Url +
  //         `/api/Attendance/GetReasonDocument/${data.users_StudentAtendanceFiles[0].aI_Id}`
  //     );
  // };
  return (
    <div className="attendanceBtnWrap">
      {!hasReason && (
        <div className="attendanceBtn" onClick={onBtnClick}>
          Upload Excuse
        </div>
      )}
      {hasReason && (
        <div className="attendanceBtn" onClick={downloadFile}>
          Download File
        </div>
      )}
      <input
        type="file"
        name="myImage"
        ref={inputFileRef}
        style={{ width: "0px", height: "0px" }}
        onChange={(event) => {
          const files = event.target.files;
          const attId = data.att_Id;
          const formData = new FormData();
          formData.append("FileToUpload", files[0]);
          formData.append("Ref_Id", attId);
          formData.append("File_Type", 4);
          console.log(data.att_Id);

          axios
            .post(`http://20.46.53.208/PP/api/File/UploadFile`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              return response.data;
            })
            .then((data) => {
              console.log(data);
              var refID = data.fileId;

              axios({
                url:
                  _Url +
                  `/api/Attendance/UploadAttendanceFile/${refID}/${attId}`,
                method: "get",
                headers: {
                  Authorization: token,
                },
              })
                .then((response) => {
                  console.log(response);
                  return response.data;
                })
                .then((data) => {
                  console.log(data);
                });
            })
            .catch((error) => {
              console.error(error);
            });
        }}
      />
    </div>
  );
}

export default AttendanceBtn;
